import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="32"
    viewBox="0 0 29 32"
  >
    <g id="apollo-graphql-1" transform="translate(-5.887 -5.1)">
      <g id="Group_63" transform="translate(6.091 5.1)">
        <path
          id="Path_110"
          d="M85.466,72.4H82.494L78.2,84.642h2.691l.7-2.083H85.65l-.734-2.285H82.237l1.737-5.268,3.095,9.635H89.76Z"
          transform="translate(-68.156 -63.311)"
          fill="#fff"
        />
        <path
          id="Path_111"
          d="M34.8,30.172a.68.68,0,0,0-.562.283s-.6.674-.919,1a14.924,14.924,0,0,1-10.535,4.286,15.19,15.19,0,0,1-5.8-1.146A14.882,14.882,0,0,1,9.052,26.8a14.242,14.242,0,0,1-1.166-5.7,14.48,14.48,0,0,1,1.166-5.7,14.27,14.27,0,0,1,3.183-4.65A14.924,14.924,0,0,1,22.77,6.461a15.19,15.19,0,0,1,5.8,1.146,14.985,14.985,0,0,1,3.567,2.1,1.712,1.712,0,0,0-.1.539A1.688,1.688,0,1,0,33.73,8.591a1.453,1.453,0,0,0-.645.135A16.375,16.375,0,0,0,22.77,5.1,16.133,16.133,0,0,0,6.5,21.1a16.136,16.136,0,0,0,16.283,16,16.328,16.328,0,0,0,12.511-5.769.681.681,0,0,0,.206-.472.715.715,0,0,0-.7-.687Z"
          transform="translate(-6.704 -5.1)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)
