import React, { useState } from 'react'
import TextWithGlow from '../components/textWithGlow'
import { H1, H2, P1 } from '../components/typo'
import { ButtonGrey } from '../components/buttons'
import ServiceItemNew from '../components/serviceItemNew'
import { s } from '../style'
import DecorationPath from '../assets/paths/decorationPath'
import { Grid } from '@material-ui/core'

const { servicesSection } = require('../config')

const ServicesSection = ({ theme, language, h2 }) => {
  const { name, description, sections } = servicesSection
  const [focused, setFocused] = useState(null)
  // console.log(sections)

  return (
    <section>
      <H1 h2={h2} extraCss={h2 && sH2}>
        {name[language]}
      </H1>
      {description?.pl && <P1>{description}</P1>}

      {sections
        .filter((item, id) => (h2 ? id <= 2 : id >= 0))
        .map((section, id) => (
          <section key={id} css={sSection} id={section.hash}>
            <DecorationPath section="service" />
            <H2>
              <TextWithGlow>{section.title[language]}</TextWithGlow>
            </H2>
            <P1>{section.text[language]}</P1>
            {/* <p>{focused}</p> */}
            <Grid container css={sItems}>
              {section.itemsSmall
                ?.sort((a, b) => a.id - b.id)
                .map((item, id) => (
                  <Grid item xs={12} md={6} key={id}>
                    <ServiceItemNew
                      language={language}
                      section={section.hash}
                      id={id + section.hash}
                      key={id}
                      title={item.title[language]}
                      description={item.description[language]}
                      selectOption={item.selectOption}
                      icon={item.icon}
                      focused={focused}
                      setFocused={setFocused}
                    />
                  </Grid>
                ))}
            </Grid>
            {section.button && (
              <ButtonGrey
                internal={section.button.link.internal}
                to={section.button.link.to}
                extraCss={sButton}
              >
                {section.button.text[language]}
              </ButtonGrey>
            )}
          </section>
        ))}
    </section>
  )
}

const sH2 = {
  maringBottom: 0,
  [s.md]: {
    marginBottom: 0,
  },
}

const sSection = {
  position: 'relative',
  [s.sm_down]: { paddingTop: '4rem' },
  // [s.md]: { marginBottom: 0, paddingBottom: '20rem'}
  [s.md]: { paddingTop: '10rem', paddingBottom: '10rem' },
}

const sItems = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [s.sm_down]: { margin: '0 2rem' },
  [s.md]: { margin: '0 2rem' },
}

const sButton = {
  [s.md]: { marginTop: '4rem' },
}

export default ServicesSection
