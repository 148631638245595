import React from 'react'

import { TextField, Grid } from '@material-ui/core'
import { s, colors, alpha } from '../style'

const { contactForm } = require('../config')

const ContactForm = ({ formData, setFormData, language }) => {
  const { message, sender, phone, email } = contactForm

  const onValueChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value })

  return (
    <Grid container css={sInputs}>
      <Grid item xs={12}>
        <TextField
          onChange={onValueChange}
          name="message"
          value={formData.message}
          css={sTextField}
          label={message.name[language]}
          variant="filled"
          multiline
          fullWidth
          rows={4}
        />
      </Grid>
      <Grid item xs={12} container>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={3}>
            <TextField
              required
              onChange={onValueChange}
              name="name"
              value={formData.name}
              css={sTextField}
              label={sender.name[language]}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              required
              onChange={onValueChange}
              name="phone"
              value={formData.phone}
              type="tel"
              css={sTextField}
              label={phone.name[language]}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              required
              onChange={onValueChange}
              name="email"
              value={formData.email}
              type="email"
              css={sTextField}
              label={email.name[language]}
              variant="filled"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const sInputs = {
  // justifyContent: 'center',
  paddingLeft: '2rem',
}

const sTextField = {
  fontFamily: 'Quicksand',
  margin: '0rem 0rem 1.5rem',
  width: '100%',
  label: {
    color: colors.white.concat(alpha[60]),
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    marginLeft: '0.5rem',
  },
  'input, textarea': {
    transition: 'color 0.2s ease-out',
    marginLeft: '0.625rem',
    color: colors.white.concat(alpha[60]),
  },
  div: {
    transition: 'background-color 0.3s ease-out',
    borderRadius: '1rem',
    backgroundColor: colors.white.concat(alpha[8]),
  },
  '.MuiFilledInput-underline:before': {
    borderBottom: 'none',
  },
  '.MuiFilledInput-underline:after': {
    width: 'calc(100% - 2rem)',
    left: '1rem',
  },
  '.MuiFilledInput-underline:before .Mui-focuesd': {
    borderBottom: 'none',
  },
  '&:focus, &:hover': {
    'input, textarea': { color: colors.white },
    div: { backgroundColor: colors.white.concat(alpha[16]) },
  },
}

export default ContactForm

// return (
//   <Grid container css={sTextFields}>
//     <Grid item xs={12}><TextField
//       onChange={onValueChange}
//       name='message'
//       css={sTextField}
//       label={message.name[language]}
//       rows={4}/></Grid>
//     <Grid item xs={12} md={4}><TextField
//       onChange={onValueChange}
//       name='name'
//       css={sTextField}
//       label={sender.name[language]}
//       /></Grid>
//     <Grid item xs={12} md={4}><TextField
//       onChange={onValueChange}
//       name='phone'
//       type='tel'
//       css={sTextField}
//       label={phone.name[language]}
//       /></Grid>
//     <Grid item xs={12} md={4}><TextField
//       onChange={onValueChange}
//       name='email'
//       type='email'
//       css={sTextField}
//       label={email.name[language]}
//      /></Grid>
//   </Grid>
// )
