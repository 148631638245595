import React from 'react'
import { colors, alpha, s } from '../style'

const money = {
  min: 0,
  max: 150000,
  step: 1000,
}

const font = {
  min: 24,
  max: 32,
}

const styleMoneySwitch = (value) => {
  if (value >= 0 && value <= 15000) return sMoneyValue.small
  if (value > 15000 && value <= 50000) return sMoneyValue.mid
  if (value > 50000 && value <= 100000) return sMoneyValue.large
  if (value > 100000) return sMoneyValue.super
}

const ValuationSlider = ({ theme, language, budgetState, setBudgetState }) => {
  return (
    <div css={[sContainer, styleMoneySwitch(budgetState)]}>
      <div css={[sMoney]}>
        <span className="sMoneyMain">
          {budgetState.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </span>
        <span css={sMoney.pln}>PLN</span>
      </div>
      <div
        css={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* <div
          css={[
            sSlider,
            { width: `calc(${(budgetState / money.max) * 100 + '%'})` },
          ]}
          className="sSlider"
        /> */}
        <input
          css={[sInput2, budgetState <= 0 && sInput2.inactive]}
          type="range"
          min={money.min}
          max={money.max}
          step={money.step}
          value={budgetState}
          onChange={(event) => setBudgetState(event.target.value)}
        />
      </div>
    </div>
  )
}

const sContainer = {
  // width: 'calc(100% - 4rem)',
  // width: '100%',
  maring: '0 auto',
  [s.sm_down]: { paddingLeft: '2rem' },
  // margin: '0 2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContant: 'center',
  alignItems: 'center',
}

const sMoney = {
  '&, span': { transition: 'all 0.3s ease-out' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  // paddingBottom: '2rem',
  pln: {
    marginLeft: '0.5rem',
  },
  [s.sm_down]: { height: 128 },
  [s.md]: { height: 200 },
}

const sMoneyValue = {
  small: {
    textShadow: `0px 0px 16px ${colors.white.concat(alpha[30])}`,
    color: colors.white.concat(alpha[60]),
    '.sMoneyMain': { fontSize: '1.5rem' },
  },
  mid: {
    textShadow: `0px 0px 16px ${colors.white.concat(alpha[60])}`,
    color: colors.white,
    '.sMoneyMain': { fontSize: '2rem' },
  },
  large: {
    textShadow: `0px 0px 16px ${colors.yellowOrange.concat(alpha[60])}`,
    color: colors.yellowOrange,
    '.sMoneyMain': { fontSize: '2.5rem' },
  },
  super: {
    textShadow: `0px 0px 16px ${colors.yellowOrange}`,
    color: colors.yellowOrange,
    '.sMoneyMain': { fontSize: '3rem' },
  },
}
const sInput2 = {
  outline: 0,
  border: 0,
  borderRadius: 500,
  width: 400,
  maxWidth: '100%',
  // margin: '24px 0 16px',
  transition:
    'box-shadow 0.2s ease-in-out, background 0.3s ease-out, filter 0.3s ease-out',
  // Chrome
  '@media screen and (-webkit-min-device-pixel-ratio:0)': {
    '&': {
      overflow: 'hidden',
      height: 40,
      WebkitAppearance: 'none',
      backgroundColor: colors.yellowOrange.concat(alpha[30]),
    },
    '&::-webkit-slider-runnable-track': {
      height: 40,
      WebkitAppearance: 'none',
      color: '#444',
      // margin-top: -1px;
      transition: 'box-shadow 0.2s ease-in-out',
    },
    '&::-webkit-slider-thumb': {
      width: 40,
      WebkitAppearance: 'none',
      height: 40,
      cursor: 'ew-resize',
      background: '#fff',
      boxShadow: `-340px 0 0 320px ${colors.yellowOrange}, inset 0 0 0 40px ${colors.yellowOrange}`,
      borderRadius: '50%',
      transition: 'box-shadow 0.2s ease-in-out',
      position: 'relative',
      // top: 1px;
    },
    '&:active::-webkit-slider-thumb': {
      background: '#fff',
      boxShadow: `-340px 0 0 320px ${colors.yellowOrange}, inset 0 0 0 3px ${colors.yellowOrange}`,
    },
  },
  // Firefox,
  '&::-moz-range-progress': {
    backgroundColor: colors.yellow,
  },
  '&::-moz-range-track': {
    backgroundColor: colors.yellow,
  },
  // IE,
  '&::-ms-fill-lower': {
    backgroundColor: colors.yellow,
  },
  '&::-ms-fill-upper': {
    backgroundColor: colors.yellow.concat(alpha[30]),
  },
  inactive: {
    filter: 'grayscale(1)',
  },
}

export default ValuationSlider
