import React from 'react'
import { H1, P1 } from '../components/typo'
import { s, colors, alpha } from '../style'

const PolicyPage = () => {
  return (
    <div>
      <div css={style}>
        <p>
          <b>1. POSTANOWIENIA OGÓLNE</b>
        </p>
        <p>
          <b>1.1. </b> Niniejsza polityka prywatności strony internetowej
          www.appclever.pl (dalej Strona Internetowa) ma charakter informacyjny,
          co oznacza że nie jest ona źródłem obowiązków Klientów korzystających
          ze Stron Internetowej. Polityka prywatności zawiera przede wszystkim
          zasady dotyczące przetwarzania danych osobowych przez Administratora
          na Stronie Internetowej podczas korzystania z usług świadczonych przez
          Administratora danych osobowych, w tym podstawy, cele i zakres
          przetwarzania danych osobowych oraz prawa osób, których dane dotyczą,
          a także informacje w zakresie stosowania w na Stronie Internetowej
          plików cookies oraz narzędzi analitycznych.
        </p>
        <p>
          <b>1.2. </b> Administratorem danych osobowych zbieranych za
          pośrednictwem Strony Internetowej jest APPCLEVER Sp. Z o.o. z siedzibą
          w Gdańsku, 80-369 Gdańsk, AL. RZECZYPOSPOLITEJ, nr 4C, lok. 172,
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego
          prowadzonego przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VII Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem: 0000842702,
          NIP:5842793647, REGON: 38611845500000 - zwany dalej „Administratorem”
          i będący jednocześnie Dostawcą usług prezentowanych na Stronie
          Internetowej.
        </p>
        <p>
          <b>1.3. </b>Dane osobowe przetwarzane są przez Administratora zgodnie
          z obowiązującymi przepisami prawa, w szczególności zgodnie z
          rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
          27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
          o ochronie danych) - zwane dalej „RODO” lub „Rozporządzenie RODO”, a
          także przepisami ustawy o ochronie danych osobowych z dnia 10 maja
          2018 r. (Dz.U.2018.1000 ze zm.).
        </p>
        <p>
          <b>1.4.</b> Korzystanie ze Strony Internetowej jest dobrowolne.
          Podobnie związane z tym podanie danych osobowych przez korzystającego
          z usług prezentowanych na Stronie Internetowej Klienta jest
          dobrowolne, z zastrzeżeniem dwóch wyjątków: (1) zawieranie umów z
          Administratorem - niepodanie w przypadkach i w zakresie wskazanym na
          Stronie Internetowej oraz w Regulaminie świadczenia usług drogą
          elektroniczną (dalej Regulamin Ogólny) i niniejszej polityce
          prywatności danych osobowych niezbędnych do zawarcia i wykonania umowy
          o świadczenie usług (dalej Umowa) z Administratorem skutkuje brakiem
          możliwości zawarcia tejże Umowy. Podanie danych osobowych jest w takim
          wypadku wymogiem umownym i jeżeli osoba, które dane dotyczą chce
          zawrzeć daną umowę z Administratorem, to jest zobowiązana do podania
          wymaganych danych. Każdorazowo zakres danych wymaganych do zawarcia
          umowy wskazany jest uprzednio na Stronie Internetowej oraz w
          Regulaminie Ogólnym; (2) obowiązki ustawowe Administratora - podanie
          danych osobowych jest wymogiem ustawowym wynikającym z powszechnie
          obowiązujących przepisów prawa nakładających na Administratora
          obowiązek przetwarzania danych osobowych (np. przetwarzanie danych w
          celu prowadzenia ksiąg podatkowych lub rachunkowych) i brak ich
          podania uniemożliwi Administratorowi wykonanie tychże obowiązków.
        </p>
        <p>
          <b>1.5.</b> Administrator dokłada szczególnej staranności w celu
          ochrony interesów osób, których przetwarzane przez niego dane osobowe
          dotyczą, a w szczególności jest odpowiedzialny i zapewnia, że zbierane
          przez niego dane są: (1) przetwarzane zgodnie z prawem; (2) zbierane
          dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu
          przetwarzaniu niezgodnemu z tymi celami; (3) merytorycznie poprawne i
          adekwatne w stosunku do celów, w jakich są przetwarzane; (4)
          przechowywane w postaci umożliwiającej identyfikację osób, których
          dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu
          przetwarzania oraz (5) przetwarzane w sposób zapewniający odpowiednie
          bezpieczeństwo danych osobowych, w tym ochronę przed niedozwolonym lub
          niezgodnym z prawem przetwarzaniem oraz przypadkową utratą,
          zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków
          technicznych lub organizacyjnych.
        </p>
        <p>
          <b>1.6.</b> Uwzględniając charakter, zakres, kontekst i cele
          przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych
          o różnym prawdopodobieństwie i wadze zagrożenia, Administrator wdraża
          odpowiednie środki techniczne i organizacyjne, aby przetwarzanie
          odbywało się zgodnie z niniejszym rozporządzeniem i aby móc to
          wykazać. Środki te są w razie potrzeby poddawane przeglądom i
          uaktualniane. Administrator stosuje środki techniczne zapobiegające
          pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych
          osobowych przesyłanych drogą elektroniczną.
        </p>
        <p>
          <b>1.7.</b> Wszelkie słowa, wyrażenia i akronimy występujące w
          niniejszej polityce prywatności i rozpoczynające się dużą literą
          należy rozumieć zgodnie z ich definicją zawartą w Regulaminie Ogólnym.
        </p>
        <p>
          <b>2. PODSTAWY PRZETWARZANIA DANYCH </b>
        </p>
        <p>
          <b>2.1.</b> Administrator uprawniony jest do przetwarzania danych
          osobowych w przypadkach, gdy – i w takim zakresie, w jakim – spełniony
          jest co najmniej jeden z poniższych warunków: (1) osoba, której dane
          dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w
          jednym lub większej liczbie określonych celów; (2) przetwarzanie jest
          niezbędne do wykonania umowy, której stroną jest osoba, której dane
          dotyczą, lub do podjęcia działań na żądanie osoby, której dane
          dotyczą, przed zawarciem umowy; (3) przetwarzanie jest niezbędne do
          wypełnienia obowiązku prawnego ciążącego na Administratorze; lub (4)
          przetwarzanie jest niezbędne do celów wynikających z prawnie
          uzasadnionych interesów realizowanych przez Administratora lub przez
          stronę trzecią, z wyjątkiem sytuacji, w których nadrzędny charakter
          wobec tych interesów mają interesy lub podstawowe prawa i wolności
          osoby, której dane dotyczą, wymagające ochrony danych osobowych, w
          szczególności gdy osoba, której dane dotyczą, jest dzieckiem.
        </p>
        <p>
          <b>2.2.</b> Przetwarzanie danych osobowych przez Administratora wymaga
          każdorazowo zaistnienia co najmniej jednej z podstaw wskazanych w pkt.
          2.1 polityki prywatności. Konkretne podstawy przetwarzania danych
          osobowych Klientów przez Administratora są wskazane w kolejnym punkcie
          polityki prywatności – w odniesieniu do danego celu przetwarzania
          danych osobowych przez Administratora.
        </p>
        <p>
          <b>
            3. CEL, PODSTAWA, OKRES I ZAKRES PRZETWARZANIA DANYCH NA STRONIE
            INTERNETOWEJ
          </b>
        </p>
        <p>
          <b>3.1.</b> Każdorazowo cel, podstawa, okres i zakres oraz odbiorcy
          danych osobowych przetwarzanych przez Administratora wynika z działań
          podejmowanych przez danego Klienta na Stronie Internetowej.
        </p>
        <p>
          <b>3.2.</b> Dane podawane przez użytkowników w celu korzystania z
          usług są używane w procesie ich uruchamiania i świadczenia. Obejmuje
          to przede wszystkim takie działania, jak:
        </p>
        <ol>
          <li>techniczne uruchomienie usług,</li>
          <li>wystawanie faktur,</li>
          <li>
            przetwarzanie i przechowywanie dokumentów finansowych w oparciu o
            przepisy szczególne: podatkowe, finansowo-księgowe itp.,
          </li>
          <li>
            informowanie o terminach wygasania usług i możliwości ich
            przedłużania,
          </li>
          <li>informowanie o planowanych pracach technicznych,</li>
          <li>informowanie o istotnych zmianach konfiguracyjnych,</li>
          <li>informowanie o zmianach regulaminów,</li>
          <li>
            realizację obsługi technicznej, w tym odpowiedzi na pytania
            użytkowników,
          </li>
          <li>wyjaśnienia w zakresie rozliczeń,</li>
          <li>bezpośredni kontakt handlowy ,</li>
          <li>
            przesyłanie informacji marketingowych - jeśli użytkownik wyraża
            zgodę.
          </li>
        </ol>
        <p>
          <b>3.3.</b> Dostawca, jako przedsiębiorstwo oferujące usługę hostingu,
          może być także procesorem danych osobowych – w odniesieniu do danych,
          których administratorami są klienci i które zostały mu powierzone
          poprzez zawarcie odpowiedniej umowy powierzenia przetwarzania danych
          osobowych. Szczegółowe zasady określa wówczas ta umowa, a niniejsza
          polityka prywatności nie dotyczy wykorzystania tego rodzaju danych. W
          odniesieniu do tych danych Dostawca nie jest Administratorem.
        </p>
        <p>
          <b>3.4.</b> Administrator może przetwarzać dane osobowe w
          następujących celach, na następujących podstawach, w okresach oraz w
          następującym zakresie:
        </p>
        <table>
          <tbody>
            <tr>
              <td width="205">
                <b>Cel przetwarzania</b>
              </td>
              <td width="205">
                <b>
                  Podstawa prawna przetwarzania i okres przechowywania danych
                </b>
              </td>
              <td width="205">
                <b>Zakres przetwarzanych danych</b>
              </td>
            </tr>
            <tr>
              <td width="205">
                Wykonanie Umowy lub podjęcie działań na żądanie osoby, której
                dane dotyczą, przed zawarciem Umowy
              </td>
              <td width="205">
                {' '}
                Artykuł 6 ust. 1 lit. b) Rozporządzenia RODO (wykonanie umowy)
                <br />
                <br />
                Dane są przechowywane przez okres niezbędny do wykonania,
                rozwiązania lub wygaśnięcia w inny sposób zawartej umowy.
              </td>
              <td width="205">
                Zakres maksymalny: imię i nazwisko; adres poczty elektronicznej;
                numer telefonu kontaktowego; adres (ulica, numer domu, numer
                lokalu, kod pocztowy, miejscowość, kraj), adres
                zamieszkania/prowadzenia działalności/siedziby , nr PESEL. W
                wypadku Klientów nie będących konsumentami Administrator może
                przetwarzać dodatkowo nazwę firmy oraz numer identyfikacji
                podatkowej (NIP) Klienta.
              </td>
            </tr>
            <tr>
              <td width="205">Marketing bezpośredni</td>
              <td width="205">
                Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie
                uzasadniony interes administratora)
                <br />
                Marketing bezpośredni - do momentu wyrażenia sprzeciwu przez
                Klienta Artykuł 22 RODO.
              </td>
              <td width="205">Adres poczty elektronicznej</td>
            </tr>
            <tr>
              <td width="205">
                Marketing, wysyłanie informacji handlowej drogą elektroniczną.
              </td>
              <td width="205">
                Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgoda)
                <br />
                <br />
                Dane przechowywane są do momentu wycofania zgody przez osobę,
                której dane dotyczą na dalsze przetwarzanie jej danych w tym
                celu.
              </td>
              <td width="205">Imię, adres poczty elektronicznej</td>
            </tr>
            <tr>
              <td width="205">
                Wyrażenie przez Klienta opinii o zawartej Umowie Sprzedaży
              </td>
              <td width="205">
                Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO
                <br />
                <br />
                Dane przechowywane są do momentu wycofania zgody przez osobę,
                której dane dotyczą na dalsze przetwarzanie jej danych w tym
                celu.
              </td>
              <td width="205">Adres poczty elektronicznej</td>
            </tr>
            <tr>
              <td width="205">Prowadzenie ksiąg rachunkowych</td>
              <td width="205">
                Prowadzenie ksiąg rachunkowych Artykuł 6 ust. 1 lit. c)
                Rozporządzenia RODO w zw. z art. 86 § 1 Ordynacji podatkowej tj.
                z dnia 17 stycznia 2017 r. (Dz.U. z 2017 r. poz. 201)
                <br />
                <br />
                Dane są przechowywane przez okres wymagany przepisami prawa
                nakazującymi Administratorowi przechowywanie ksiąg podatkowych
                (do czasu upływu okresu przedawnienia zobowiązania podatkowego,
                chyba że ustawy podatkowe stanowią inaczej). Imię i nazwisko;
                adres zamieszkania/prowadzenia działalności/siedziby, nazwa
                firmy oraz numer identyfikacji podatkowej (NIP) Usługobiorcy lub
                Klienta, dane rachunku bankowego. W tym również dane niezbędne
                ze względu na rozliczenie usługi - wszelkie dane o zamówieniach
                (historia zamówień).
              </td>
              <td width="205">
                Imię i nazwisko; adres zamieszkania/prowadzenia
                działalności/siedziby, nazwa firmy oraz numer identyfikacji
                podatkowej (NIP) Usługobiorcy lub Klienta, dane rachunku
                bankowego.
                <br />
                <br />W tym również dane niezbędne ze względu na rozliczenie
                usługi - wszelkie dane o zamówieniach (historia zamówień).
              </td>
            </tr>
            <tr>
              <td width="205">
                Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić
                Administrator lub jakie mogą być podnoszone wobec Administratora
              </td>
              <td width="205">
                {' '}
                Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić
                Administrator lub jakie mogą być podnoszone wobec Administratora
                Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO
                <br />
                <br />
                Dane są przechowywane przez okres istnienia prawnie
                uzasadnionego interesu realizowanego przez Administratora, nie
                dłużej jednak niż przez okres przedawnienia roszczeń w stosunku
                do osoby, której dane dotyczą, z tytułu prowadzonej przez
                Administratora działalności gospodarczej. Okres przedawnienia
                określają przepisy prawa, w szczególności kodeksu cywilnego
                (podstawowy termin przedawnienia dla roszczeń związanych z
                prowadzeniem działalności gospodarczej wynosi trzy lata, a dla
                umowy sprzedaży dwa lata). Imię i nazwisko; numer telefonu
                kontaktowego; adres poczty elektronicznej; adres (ulica, numer
                domu, numer lokalu, kod pocztowy, miejscowość, kraj), adres
                zamieszkania/prowadzenia działalności/siedziby, nr PESEL. W
                wypadku Klientów nie będących konsumentami Administrator może
                przetwarzać dodatkowo nazwę firmy oraz numer identyfikacji
                podatkowej (NIP) Usługobiorcy lub Klienta.
              </td>
              <td width="205">
                Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić
                Administrator lub jakie mogą być podnoszone wobec Administratora
                Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO Dane są
                przechowywane przez okres istnienia prawnie uzasadnionego
                interesu realizowanego przez Administratora, nie dłużej jednak
                niż przez okres przedawnienia roszczeń w stosunku do osoby,
                której dane dotyczą, z tytułu prowadzonej przez Administratora
                działalności gospodarczej. Okres przedawnienia określają
                przepisy prawa, w szczególności kodeksu cywilnego (podstawowy
                termin przedawnienia dla roszczeń związanych z prowadzeniem
                działalności gospodarczej wynosi trzy lata, a dla umowy
                sprzedaży dwa lata). Imię i nazwisko; numer telefonu
                kontaktowego; adres poczty elektronicznej; adres (ulica, numer
                domu, numer lokalu, kod pocztowy, miejscowość, kraj), adres
                zamieszkania/prowadzenia działalności/siedziby, nr PESEL.
                <br />
                <br />W wypadku Klientów nie będących konsumentami Administrator
                może przetwarzać dodatkowo nazwę firmy oraz numer identyfikacji
                podatkowej (NIP) Usługobiorcy lub Klienta.
              </td>
            </tr>
            <tr>
              <td width="205">
                Dane charakteryzujące sposób korzystania z usługi świadczonej
                drogą elektroniczną (zapewnienie parametrów jakościowych usług,
                utrzymywanie środków bezpieczeństwa, obsługa zapytań, ustalanie
                przypadków niedozwolonego korzystania z usługi oraz
                przekazywanie danych organom uprawnionym)
              </td>
              <td width="205">
                Art. 18 ust. 5 - 6 ustawy o świadczeniu usług drogą
                elektroniczną
                <br />
                <br />
                Art. 6 ust. 1 pkt f) RODO Dane są przechowywane przez czas
                trwania usługi, a później do upływu okresu przedawnienia
                ewentualnych roszczeń.
              </td>
              <td width="205">
                Imię i nazwisko; numer telefonu kontaktowego; adres poczty
                elektronicznej; adres (ulica, numer domu, numer lokalu, kod
                pocztowy, miejscowość, kraj), adres zamieszkania/prowadzenia
                działalności/siedziby, nr PESEL. W wypadku Klientów nie będących
                konsumentami Administrator może przetwarzać dodatkowo nazwę
                firmy oraz numer identyfikacji podatkowej (NIP) Usługobiorcy lub
                Klienta.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <b>4.POSTANOWIENIA OGÓLNEODBIORCY DANYCH OSOBOWYCH</b>
        </p>
        <p>
          <b>4.1.</b> Dla prawidłowego funkcjonowania Strony Internetowej, w tym
          dla realizacji zawieranych Umów konieczne jest korzystanie przez
          Administratora z usług podmiotów zewnętrznych (takich jak np. podmiot
          obsługujący płatności, podmioty prowadzące rejestry abonentów,
          przedsiębiorcy z branży marketingu, obsługa prawna, księgowa,
          audytorzy, kurierzy). Administrator korzysta wyłącznie z usług takich
          podmiotów przetwarzających, którzy zapewniają wystarczające gwarancje
          wdrożenia odpowiednich środków technicznych i organizacyjnych, tak by
          przetwarzanie spełniało wymogi Rozporządzenia RODO i chroniło prawa
          osób, których dane dotyczą.
        </p>
        <p>
          <b>4.2.</b> Przekazanie danych przez Administratora nie następuje w
          każdym wypadku i nie do wszystkich wskazanych w polityce prywatności
          odbiorców lub kategorii odbiorców – Administrator przekazuje dane
          wyłącznie wtedy, gdy jest to niezbędne do realizacji danego celu
          przetwarzania danych osobowych i tylko w zakresie niezbędnym do jego
          zrealizowania.
        </p>
        <p>
          <b>4.3.</b> Dane osobowe Klientów mogą być przekazywane następującym
          odbiorcom lub kategoriom odbiorców:
        </p>
        <ol type="a">
          <li>
            przewoźnicy / spedytorzy / brokerzy kurierscy - w przypadku Klienta,
            który korzysta ze sposobu dostawy rzeczy przesyłką pocztową lub
            przesyłką kurierską, Administrator udostępnia zebrane dane osobowe
            Klienta wybranemu przewoźnikowi, spedytorowi lub pośrednikowi
            realizującemu przesyłki na zlecenie Administratora w zakresie
            niezbędnym do zrealizowania dostawy Produktu Klientowi,
          </li>
          <li>
            podmioty obsługujące płatności elektroniczne lub kartą płatniczą - w
            przypadku Klienta, który korzysta ze sposobu płatności
            elektronicznych lub kartą płatniczą Administrator udostępnia zebrane
            dane osobowe Klienta wybranemu podmiotowi obsługującemu powyższe
            płatności na zlecenie Administratora w zakresie niezbędnym do
            obsługi płatności realizowanej przez Klienta,
          </li>
          <li>
            dostawcy usług zaopatrujący Administratora w rozwiązania techniczne,
            informatyczne oraz organizacyjne, umożliwiające Administratorowi
            prowadzenie działalności gospodarczej - Administrator udostępnia
            zebrane dane osobowe Klienta wybranemu dostawcy działającemu na jego
            zlecenie jedynie w przypadku oraz w zakresie niezbędnym do
            zrealizowania danego celu przetwarzania danych zgodnego z niniejszą
            polityką prywatności.
          </li>
          <li>
            dostawcy usług księgowych, prawnych i doradczych zapewniający
            Administratorowi wsparcie księgowe, prawne lub doradcze (w
            szczególności biuro księgowe, kancelaria prawna lub firma
            windykacyjna) - Administrator udostępnia zebrane dane osobowe
            Klienta wybranemu dostawcy działającemu na jego zlecenie jedynie w
            przypadku oraz w zakresie niezbędnym do zrealizowania danego celu
            przetwarzania danych zgodnego z niniejszą polityką prywatności.
          </li>
        </ol>
        <p>
          <b>5. PROFILOWANIE </b>
        </p>
        <p>
          <b>5.1.</b> Rozporządzenie RODO nakłada na Administratora obowiązek
          informowania o zautomatyzowanym podejmowaniu decyzji, w tym o
          profilowaniu, o którym mowa w art. 22 ust. 1 i 4 Rozporządzenia RODO,
          oraz – przynajmniej w tych przypadkach – istotne informacje o zasadach
          ich podejmowania, a także o znaczeniu i przewidywanych konsekwencjach
          takiego przetwarzania dla osoby, której dane dotyczą. Mając to na
          uwadze Administrator podaje w tym punkcie polityki prywatności
          informacje dotyczące możliwego profilowania.
        </p>
        <p>
          <b>5.2.</b>Administrator może korzystać na Stronie Internetowej z
          profilowania do celów marketingu bezpośredniego, ale decyzje
          podejmowane na jego podstawie przez Administratora nie dotyczą
          zawarcia lub odmowy zawarcia Umowy. Efektem korzystania z profilowania
          może być np. przyznanie danej osobie rabatu, przesłanie jej kodu
          rabatowego, , przesłanie propozycji Usługi, która może odpowiadać
          zainteresowaniom lub preferencjom danej osoby lub też zaproponowanie
          lepszych warunków w porównaniu do standardowej oferty znajdującej się
          na Stronie Internetowej. Mimo profilowania to dana osoba podejmuje
          swobodnie decyzję, czy będzie chciała skorzystać z otrzymanego w ten
          sposób rabatu, czy też lepszych warunków ofertowych.
        </p>
        <p>
          <b>5.3.</b> Profilowanie polega na automatycznej analizie lub
          prognozie zachowania danej osoby na Stronie Internetowej np. poprzez
          wybranie konkretnej Usługi, przeglądanie opisu konkretnej Usługi, czy
          też poprzez analizę dotychczasowej historii zakupionych dotychczas
          Usług. Warunkiem takiego profilowania jest posiadanie przez
          Administratora danych osobowych danej osoby, aby móc jej następnie
          przesłać np. kod rabatowy.
        </p>
        <p>
          <b>5.4. </b>Osoba, której dane dotyczą, ma prawo do tego, by nie
          podlegać decyzji, która opiera się wyłącznie na zautomatyzowanym
          przetwarzaniu, w tym profilowaniu, i wywołuje wobec tej osoby skutki
          prawne lub w podobny sposób istotnie na nią wpływa.
        </p>
        <p>
          <b>6. PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ</b>
        </p>
        <p>
          <b>6.1.</b> Prawo dostępu, sprostowania, ograniczenia, usunięcia lub
          przenoszenia - osoba, której dane dotyczą, ma prawo żądania od
          Administratora dostępu do swoich danych osobowych, ich sprostowania,
          usunięcia („prawo do bycia zapomnianym”) lub ograniczenia
          przetwarzania oraz ma prawo do wniesienia sprzeciwu wobec
          przetwarzania, a także ma prawo do przenoszenia swoich danych.
          Szczegółowe warunki wykonywania wskazanych wyżej praw wskazane są w
          art. 15-21 Rozporządzenia RODO.
        </p>
        <p>
          <b>6.2. </b>Prawo do cofnięcia zgody w dowolnym momencie – osoba,
          której dane przetwarzane są przez Administratora na podstawie
          wyrażonej zgody (na podstawie art. 6 ust. 1 lit. a) lub art. 9 ust. 2
          lit. a) Rozporządzenia RODO), to ma ona prawo do cofnięcia zgody w
          dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
          którego dokonano na podstawie zgody przed jej cofnięciem.
        </p>
        <p>
          <b>6.3.</b> Prawo wniesienia skargi do organu nadzorczego – osoba,
          której dane przetwarzane są przez Administratora, ma prawo wniesienia
          skargi do organu nadzorczego w sposób i trybie określonym w przepisach
          Rozporządzenia RODO oraz prawa polskiego, w szczególności ustawy o
          ochronie danych osobowych. Organem nadzorczym w Polsce jest Prezes
          Urzędu Ochrony Danych Osobowych.
        </p>
        <p>
          <b>6.4. </b>Prawo do sprzeciwu - osoba, której dane dotyczą, ma prawo
          w dowolnym momencie wnieść sprzeciw – z przyczyn związanych z jej
          szczególną sytuacją – wobec przetwarzania dotyczących jej danych
          osobowych opartego na art. 6 ust. 1 lit. e) (interes lub zadania
          publiczne) lub f) (prawnie uzasadniony interes administratora), w tym
          profilowania na podstawie tych przepisów. Administratorowi w takim
          przypadku nie wolno już przetwarzać tych danych osobowych, chyba że
          wykaże on istnienie ważnych prawnie uzasadnionych podstaw do
          przetwarzania, nadrzędnych wobec interesów, praw i wolności osoby,
          której dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony
          roszczeń.
        </p>
        <p>
          <b> 6.5.</b>Prawo do sprzeciwu dot. marketingu bezpośredniego - jeżeli
          dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego,
          osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść
          sprzeciw wobec przetwarzania dotyczących jej danych osobowych na
          potrzeby takiego marketingu, w tym profilowania, w zakresie, w jakim
          przetwarzanie jest związane z takim marketingiem bezpośrednim.
        </p>
        <p>
          <b> 6.6. </b>W celu realizacji uprawnień, o których mowa w niniejszym
          punkcie polityki prywatności można kontaktować się z Administratorem
          poprzez przesłanie stosownej wiadomości pisemnie lub pocztą
          elektroniczną na adres Administratora wskazany na wstępie polityki
          prywatności lub korzystając z formularza kontaktowego dostępnego na
          Stronie Internetowej.
        </p>

        <p>
          <b>
            7. COOKIES NA STRONIE INTERNETOWEJ, DANE EKSPLOATACYJNE I ANALITYKA
          </b>
        </p>
        <p>
          <b>7.1.</b> Pliki Cookies (ciasteczka) są to niewielkie informacje
          tekstowe w postaci plików tekstowych, wysyłane przez serwer i
          zapisywane po stronie osoby odwiedzającej Stronę Internetową (np. na
          dysku twardym komputera, laptopa, czy też na karcie pamięci smartfona
          – w zależności z jakiego urządzenia korzysta Klient). Szczegółowe
          informacje dot. plików Cookies, a także historię ich powstania można
          znaleźć m.in. tutaj: http://pl.wikipedia.org/wiki/Ciasteczko.
        </p>
        <p>
          <b>7.2. </b>Administrator może przetwarzać dane zawarte w plikach
          Cookies podczas korzystania przez odwiedzających ze Strony
          Internetowej w następujących celach:
        </p>
        <ol type="a">
          <li>
            identyfikacji Klientów jako zalogowanych na Stronie Internetowej i
            pokazywania, że są zalogowani;
          </li>
          <li>
            dostosowywania zawartości Strony Internetowej do indywidualnych
            preferencji Klienta oraz optymalizacji korzystania ze Strony
            Internetowej,
          </li>
          <li>
            prowadzenia anonimowych statystyk przedstawiających sposób
            korzystania ze Strony Internetowej;
          </li>
          <li>
            remarketingu, to jest badania cech zachowania odwiedzających Stronę
            Internetową poprzez anonimową analizę ich działań (np. powtarzające
            się wizyty na określonych stronach, słowa kluczowe itp.) w celu
            stworzenia ich profilu i dostarczenia im reklam dopasowanych do ich
            przewidywanych zainteresowań, także wtedy kiedy odwiedzają oni inne
            strony internetowe w sieci reklamowej firmy Google Inc. oraz
            Facebook Ireland Ltd.;
          </li>
        </ol>
        <p>
          <b>7.3.</b> Standardowo większość przeglądarek internetowych
          dostępnych na rynku domyślnie akceptuje zapisywanie plików Cookies.
          Każdy ma możliwość określenia warunków korzystania z plików Cookies za
          pomocą ustawień własnej przeglądarki internetowej. Oznacza to, że
          można np. częściowo ograniczyć (np. czasowo) lub całkowicie wyłączyć
          możliwość zapisywania plików Cookies – w tym ostatnim wypadku jednak
          może to mieć wpływ na niektóre funkcjonalności Strony Internetowej.
        </p>
        <p>
          <b>7.4.</b> Ustawienia przeglądarki internetowej w zakresie plików
          Cookies są istotne z punktu widzenia zgody na korzystanie z plików
          Cookies – zgodnie z przepisami taka zgoda może być również wyrażona
          poprzez ustawienia przeglądarki internetowej. W braku wyrażenia takiej
          zgody należy odpowiednio zmienić ustawienia przeglądarki internetowej
          w zakresie plików Cookies.
        </p>
        <p>
          <b>7.5.</b> Szczegółowe informacje na temat zmiany ustawień
          dotyczących plików Cookies oraz ich samodzielnego usuwania w
          najpopularniejszych przeglądarkach internetowych dostępne są w dziale
          pomocy przeglądarki internetowej oraz na poniższych stronach
          (wystarczy kliknąć w dany link):
        </p>
        <ul>
          <li>w przeglądarce Chrome</li>
          <li>w przeglądarce Firefox</li>
          <li>w przeglądarce Internet Explorer</li>
          <li>w przeglądarce Opera</li>
          <li>w przeglądarce Safari</li>
          <li>w przeglądarce Microsoft Edge</li>
        </ul>
        <p>
          <b>7.6. </b>Administrator może korzystać na Stronie Internetowej z
          usług Google Analytics, Universal Analytics dostarczanych przez firmę
          Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
          Usługi te pomagają Administratorowi analizować ruch na Stronie
          Internetowej. Gromadzone dane przetwarzane są w ramach powyższych
          usług w sposób zanonimizowany (są to tzw. dane eksploatacyjne, które
          uniemożliwiają identyfikację osoby) do generowania statystyk pomocnych
          w administrowaniu Stroną Internetową. Dane te mają charakter zbiorczy
          i anonimowy, tj. nie zawierają cech identyfikujących (danych
          osobowych) osoby odwiedzające Stronę Internetową. Administrator
          korzystając z powyższych usług na Stronie Internetowej gromadzi takie
          dane jak źródła i medium pozyskania odwiedzających Stronę Internetową
          oraz sposób ich zachowania na Stronie Internetowej, informacje na
          temat urządzeń i przeglądarek z których odwiedzają stronę, IP oraz
          domenę, dane geograficzne oraz dane demograficzne (wiek, płeć) i
          zainteresowania.
        </p>
        <p>
          <b> 7.7.</b> Możliwe jest zablokowanie w łatwy sposób przez daną osobę
          udostępniania Google Analytics informacji o jej aktywności na Stronie
          Internetowej - w tym celu można zainstalować dodatek do przeglądarki
          udostępniany przez firmę Google Inc. dostępny tutaj:
          https://tools.google.com/dlpage/gaoptout?hl=pl.
        </p>
        <p>
          <b>8. POSTANOWIENIA KOŃCOWE.</b>
        </p>
        <p>
          <b>8.1.</b> Strona Internetowa może zawierać odnośniki do innych stron
          internetowych. Administrator namawia by po przejściu na inne strony,
          zapoznać się z polityką prywatności tam ustaloną. Niniejsza polityka
          prywatności dotyczy tylko Strony Internetowej Administratora.
        </p>
      </div>
    </div>
  )
}

const style = {
  [s.sm]: {
    padding: '2rem 10vw',
  },
  [s.xs]: { 'ol, p': { paddingLeft: '2rem' }, ol: { marginLeft: '2rem' } },
  'ol, li': { fontSize: 0.875 + 'rem' },
  ol: { padding: 0.5 + 'rem', marginLeft: '2rem' },
  'p, li': { marginBottom: 0.5 + 'rem' },
  p: {
    fontSize: '0.875rem',
    marginBottom: '2rem',
    color: '#ffffff'.concat(alpha[50]),
    b: {
      color: '#ffffff'.concat(alpha[100]),
      fontSize: '1rem',
      marginTop: '1rem',
    },
  },
  table: {
    maxWidth: '100vw',
    fontSize: 0.75 + 'rem',
    textAlign: 'left',
    borderCollapse: 'collapse',
    width: '100%',
    margin: '0 0 30px',
    boxSizing: 'border-box',
    border: '1px solid rgba(167, 167, 167, .2) !important',
    overflow: 'scroll',
  },
  td: {
    padding: '30px 5px',
    border: '1px solid rgba(167, 167, 167, .2) !important',
  },
}

export default PolicyPage
