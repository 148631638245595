import React, { useContext, useEffect } from 'react'
import { StateContext, DispatchContext } from '../context'

import SEO from '../components/seo'
import Layout from '../components/layout'

// Pages
import IndexPage from './IndexPage'
import AboutPage from './AboutPage'
import CoopPage from './CoopPage'
import ServicesPage from './ServicesPage'
import ValuationPage from './ValuationPage'
import CareerPage from './CareerPage'
import ContactPage from './ContactPage'
import PolicyPage from './PolicyPage'
import DownloadPage from './DownloadPage'

const Pages = {
  IndexPage,
  AboutPage,
  CoopPage,
  ServicesPage,
  ValuationPage,
  CareerPage,
  ContactPage,
  PolicyPage,
  DownloadPage,
}

const _subpageCreator = ({ pageContext }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const language = state && state.language ? state.language : 'pl'
  const theme = state && state.theme ? state.theme : 'dark'

  // console.log({ pageContext })

  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [dispatch])

  if (typeof Pages[pageContext.componentName] !== 'undefined') {
    return (
      <Layout key={pageContext.key}>
        <SEO
          title={pageContext.seo.title[language]}
          description={pageContext.seo.description[language]}
        />
        <div css={stylePage}>
          {React.createElement(Pages[pageContext.componentName], {
            key: pageContext.key,
            language,
            theme,
          })}
        </div>
      </Layout>
    )
  }
  return React.createElement(() => (
    <Layout>
      {/* {console.log(pageContext)} */}
      <SEO
        title={pageContext.seo.title[language] || 'ERROR - no title set'}
        description={
          pageContext.seo.description[language] || 'ERROR - no description set'
        }
      />
      <div css={stylePage}>
        Component
        <b> {pageContext.componentName || 'ERROR - no component name set!'} </b>
        | name
        <b>
          <i> {pageContext.name[language] || 'ERROR - no name set!'} </i>
        </b>
        @ URL:
        <b>
          <i> {pageContext.slug || 'ERROR - no link set!'} </i>
        </b>
        nie zostaÅ‚ stworzony
      </div>
    </Layout>
  ))
}

// Delete ot Change me later !!!
const stylePage = {
  marginTop: '6rem',
}

export default _subpageCreator
