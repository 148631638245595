import React from 'react'

import CoopModelsSection from './CoopModelsSection'
import CoopStagesSection from './CoopStagesSection'

const CoopSection = ({ theme, language }) => {
  return (
    <>
      <CoopModelsSection language={language} theme={theme} />
      <CoopStagesSection language={language} theme={theme} />
    </>
  )
}

export default CoopSection
