import React from 'react'

import AnimatedLogo2 from '../components/animatedLogo2'
import TextWithGlow from '../components/textWithGlow'
import { H1, P1 } from '../components/typo'
import { ButtonGrey } from '../components/buttons'
import { alpha, s, colors } from '../style'

const { introSection } = require('../config')

const IntroSection = ({ theme, language }) => {
  const { motto, description, button } = introSection
  return (
    <div css={outerContainer}>
      <div css={sYellowDecoration} />
      <AnimatedLogo2 />
      <div css={innerContainer}>
        <H1 extraCss={sMotto}>
          <TextWithGlow>{motto[language]}</TextWithGlow>
        </H1>
        <P1 extraCss={sDescription}>{description[language]}</P1>
        <ButtonGrey
          animate
          internal={button.internal}
          to={button.to}
          extraCss={sButtonContainer}
        >
          {button.text[language]}
        </ButtonGrey>
      </div>
    </div>
  )
}

const outerContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [s.sm_down]: { flexDirection: 'column', marginTop: '9rem' },
  [s.md]: { flexDirection: 'row', height: `calc(100vh - ${120}px)` },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}

const innerContainer = {
  display: 'flex',
  flexDirection: 'column',
  [s.md]: {
    marginTop: '2rem',
    marginLeft: '2rem',
  },
  // '@keyframes fadeIn': {
  //   from: {opacity: 0},
  //   to: {opacity: 1}
  // }
}

const sMotto = {
  fontWeight: 700,
  textTransform: 'inherit',
  [s.sm_down]: { paddingBottom: '2rem', marginTop: '2rem', fontSize: '2rem' },
  [s.md]: {
    padding: '0 0 2rem',
    textAlign: 'left',
    div: { justifyContent: 'flex-start' },
  },
  animation: 'fadeIn 0.4s 1.2s both alternate',
}

const sDescription = {
  [s.sm_down]: { textAlign: 'center', paddingBottom: '0' },
  [s.md]: {
    textAlign: 'left',
    maxWidth: '75%',
    padding: '0',
    width: '100%',
    margin: '0',
  },
  animation: 'fadeIn 0.4s 1.6s both alternate',
}

const sButtonContainer = {
  [s.md]: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '50%',
  },
}

const sYellowDecoration = {
  pointerEvents: 'none',
  position: 'absolute',
  top: '-1rem',
  left: '-50%',
  width: '150vw',
  height: 500,
  background: `linear-gradient(-90deg, ${colors.white.concat(
    alpha[30]
  )}, ${colors.white.concat(alpha[0])})`,
  borderBottomLeftRadius: '100%',
  animation: 'fadeIn 0.4s 1.2s both alternate',
}

export default IntroSection
