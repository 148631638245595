import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="60"
    viewBox="0 0 64 60"
  >
    <path
      id="Union_1"
      d="M9.385,60A9.337,9.337,0,0,1,2.7,57.286,9.438,9.438,0,0,1,0,50.565V27.831H64V50.565a9.438,9.438,0,0,1-2.7,6.721A9.335,9.335,0,0,1,54.615,60ZM40.346,38h0V49.786a8.294,8.294,0,1,0,16.587,0h0V41.45H48.641v4.886h3.434v3.452a3.442,3.442,0,0,1-1.007,2.44,3.421,3.421,0,0,1-4.856,0,3.439,3.439,0,0,1-1.006-2.442V38a3.438,3.438,0,0,1,1.006-2.44,3.4,3.4,0,0,1,2.429-1.012A3.448,3.448,0,0,1,52.075,38h4.858A8.3,8.3,0,0,0,54.5,32.105a8.214,8.214,0,0,0-5.861-2.445A8.325,8.325,0,0,0,40.346,38ZM29.622,58.124h4.857L40.346,29.66H35.488l-3.433,16.67L28.618,29.66H23.76Zm-14.156,0A8.346,8.346,0,0,0,21.33,43.892a9.278,9.278,0,0,0-5.864-2.443,3.643,3.643,0,0,1-2.428-1.014h0a3.464,3.464,0,0,1,0-4.88,3.423,3.423,0,0,1,4.858,0A3.437,3.437,0,0,1,18.9,38H23.76a8.3,8.3,0,0,0-2.432-5.892,8.218,8.218,0,0,0-5.862-2.445A8.343,8.343,0,0,0,9.6,43.89c1.6,1.611,3.975,2.152,5.862,2.443v0a4.468,4.468,0,0,1,2.43,1.012h0a3.464,3.464,0,0,1,0,4.881,3.421,3.421,0,0,1-4.858,0,3.442,3.442,0,0,1-1.006-2.443H7.173A8.325,8.325,0,0,0,15.465,58.124ZM4.316,27.831a5.07,5.07,0,0,1,8.654-3.6H23.346l-7.337-7.376a5.1,5.1,0,1,1,5.07-5.1l7.337,7.376V8.7a5.116,5.116,0,0,1,0-7.207A5.04,5.04,0,0,1,31.972,0h.056a5.038,5.038,0,0,1,3.556,1.493,5.115,5.115,0,0,1,0,7.207V19.131l7.337-7.376a5.07,5.07,0,1,1,5.069,5.1l-7.337,7.376H51.03a5.07,5.07,0,0,1,8.655,3.6Z"
      fill="#ffb13b"
    />
  </svg>
)
