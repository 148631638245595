import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="43"
    viewBox="0 0 48 43"
  >
    <path
      id="accounttree"
      d="M50,22.111V3H33.2v7.167H18.8V3H2V22.111H18.8V14.944h4.8V38.833h9.6V46H50V26.889H33.2v7.167H28.4V14.944h4.8v7.167Z"
      transform="translate(-2 -3)"
      fill="#fff"
    />
  </svg>
)
