import React from 'react'
export default (
  <svg
    id="Xd"
    xmlns="http://www.w3.org/2000/svg"
    width="44.818"
    height="31.773"
    viewBox="0 0 44.818 31.773"
  >
    <path
      id="Path_79"
      data-name="Path 79"
      d="M60.012,60.412,51.775,74l8.786,14.415a.473.473,0,0,1,.055.329c-.027.11-.137.027-.3.055H54.027c-.439,0-.741-.027-.934-.3-.577-1.153-1.181-2.279-1.757-3.432-.577-1.126-1.208-2.279-1.867-3.46s-1.318-2.361-1.977-3.569h-.055c-.577,1.181-1.208,2.361-1.84,3.542S44.334,83.943,43.73,85.1c-.632,1.153-1.263,2.334-1.895,3.46-.11.275-.33.3-.632.3H35.164c-.11,0-.192.055-.192-.082a.381.381,0,0,1,.055-.3l8.539-14L35.246,60.384c-.082-.11-.11-.22-.055-.275a.318.318,0,0,1,.275-.11H41.7a1.62,1.62,0,0,1,.384.055.779.779,0,0,1,.275.247c.522,1.181,1.126,2.361,1.757,3.542.659,1.181,1.29,2.334,1.977,3.487.659,1.153,1.263,2.306,1.84,3.487h.055c.577-1.208,1.181-2.389,1.785-3.542s1.236-2.306,1.867-3.46,1.236-2.334,1.84-3.46a.467.467,0,0,1,.165-.275A.524.524,0,0,1,54,60.027h5.793a.253.253,0,0,1,.3.192c.027.027-.027.137-.082.192Z"
      transform="translate(-34.961 -57.607)"
      fill="#ff2bc2"
    />
    <path
      id="Path_80"
      data-name="Path 80"
      d="M141.857,83.057a13.65,13.65,0,0,1-5.9-1.236,9.573,9.573,0,0,1-4.146-3.734,11.881,11.881,0,0,1-1.51-6.26,11.277,11.277,0,0,1,1.51-5.793,11.4,11.4,0,0,1,4.366-4.256,14.045,14.045,0,0,1,6.947-1.593,4.9,4.9,0,0,1,.577.027c.247.027.522.027.851.055V51.591c0-.192.082-.3.275-.3H150.4a.246.246,0,0,1,.275.192V77.675c0,.494.028,1.043.055,1.647.055.577.082,1.126.11,1.592a.446.446,0,0,1-.275.439,20.97,20.97,0,0,1-4.475,1.318A24.22,24.22,0,0,1,141.857,83.057Zm2.691-5.491V65.484a7.023,7.023,0,0,0-.741-.137c-.3-.027-.6-.055-.906-.055a6.769,6.769,0,0,0-3.1.714,6.155,6.155,0,0,0-2.334,2.032,6.086,6.086,0,0,0-.906,3.487,7.652,7.652,0,0,0,.467,2.828A5.367,5.367,0,0,0,138.26,76.3a4.744,4.744,0,0,0,1.867,1.1,7.187,7.187,0,0,0,2.279.357c.412,0,.8-.027,1.153-.055A2.4,2.4,0,0,0,144.548,77.565Z"
      transform="translate(-106.017 -51.284)"
      fill="#ff2bc2"
    />
  </svg>
)
