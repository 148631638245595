import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="64"
    viewBox="0 0 57 64"
  >
    <g id="GraphQL_Logo" transform="translate(-25.74 -4.2)">
      <g id="Group_12" dataname="Group 12" transform="translate(28.577 6.29)">
        <g id="Group_11" dataname="Group 11" transform="translate(0 0)">
          <rect
            id="Rectangle_22"
            dataname="Rectangle 22"
            width="2.714"
            height="52.371"
            transform="matrix(-0.866, -0.5, 0.5, -0.866, 2.35, 46.71)"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_14" dataname="Group 14" transform="translate(28.039 47.999)">
        <g id="Group_13" dataname="Group 13" transform="translate(0)">
          <rect
            id="Rectangle_23"
            dataname="Rectangle 23"
            width="52.386"
            height="2.714"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_16" dataname="Group 16" transform="translate(29.075 47.165)">
        <g id="Group_15" dataname="Group 15" transform="translate(0 0)">
          <rect
            id="Rectangle_24"
            dataname="Rectangle 24"
            width="30.249"
            height="2.714"
            transform="matrix(-0.866, -0.5, 0.5, -0.866, 26.195, 17.475)"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_18" dataname="Group 18" transform="translate(51.846 7.776)">
        <g id="Group_17" dataname="Group 17" transform="translate(0)">
          <rect
            id="Rectangle_25"
            dataname="Rectangle 25"
            width="30.249"
            height="2.714"
            transform="matrix(-0.866, -0.5, 0.5, -0.866, 26.195, 17.475)"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_20" dataname="Group 20" transform="translate(29.082 7.766)">
        <g id="Group_19" dataname="Group 19" transform="translate(0 0)">
          <rect
            id="Rectangle_26"
            dataname="Rectangle 26"
            width="2.714"
            height="30.249"
            transform="matrix(-0.5, -0.866, 0.866, -0.5, 1.357, 17.475)"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_22" dataname="Group 22" transform="translate(51.373 6.29)">
        <g id="Group_21" dataname="Group 21" transform="translate(0 0)">
          <rect
            id="Rectangle_27"
            dataname="Rectangle 27"
            width="52.371"
            height="2.714"
            transform="matrix(-0.5, -0.866, 0.866, -0.5, 26.185, 46.71)"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_24" dataname="Group 24" transform="translate(30.115 21.076)">
        <g id="Group_23" dataname="Group 23" transform="translate(0 0)">
          <rect
            id="Rectangle_28"
            dataname="Rectangle 28"
            width="2.714"
            height="30.249"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_26" dataname="Group 26" transform="translate(75.65 21.076)">
        <g id="Group_25" dataname="Group 25" transform="translate(0 0)">
          <rect
            id="Rectangle_29"
            dataname="Rectangle 29"
            width="2.714"
            height="30.249"
            fill="#e535ab"
          />
        </g>
      </g>
      <g id="Group_28" dataname="Group 28" transform="translate(53.638 48.284)">
        <g id="Group_27" dataname="Group 27" transform="translate(0 0)">
          <rect
            id="Rectangle_30"
            dataname="Rectangle 30"
            width="2.371"
            height="26.308"
            transform="matrix(-0.5, -0.866, 0.866, -0.5, 1.185, 15.207)"
            fill="#e535ab"
          />
        </g>
      </g>
      <path
        id="Path_44"
        dataname="Path 44"
        d="M314.994,254.1a5.71,5.71,0,1,1-2.093-7.8,5.715,5.715,0,0,1,2.093,7.8"
        transform="translate(-233.032 -201.901)"
        fill="#e535ab"
      />
      <path
        id="Path_45"
        dataname="Path 45"
        d="M36.394,93.2A5.71,5.71,0,1,1,34.3,85.4a5.715,5.715,0,0,1,2.093,7.8"
        transform="translate(0 -67.295)"
        fill="#e535ab"
      />
      <path
        id="Path_46"
        dataname="Path 46"
        d="M26.6,254.1a5.71,5.71,0,1,1,7.8,2.093,5.719,5.719,0,0,1-7.8-2.093"
        transform="translate(-0.084 -201.901)"
        fill="#e535ab"
      />
      <path
        id="Path_47"
        dataname="Path 47"
        d="M305.2,93.2a5.71,5.71,0,1,1,7.8,2.093,5.719,5.719,0,0,1-7.8-2.093"
        transform="translate(-233.116 -67.295)"
        fill="#e535ab"
      />
      <path
        id="Path_48"
        dataname="Path 48"
        d="M170.806,337.413a5.706,5.706,0,1,1,5.706-5.706,5.7,5.7,0,0,1-5.706,5.706"
        transform="translate(-116.566 -269.213)"
        fill="#e535ab"
      />
      <path
        id="Path_49"
        dataname="Path 49"
        d="M170.806,15.613a5.706,5.706,0,1,1,5.706-5.706,5.7,5.7,0,0,1-5.706,5.706"
        transform="translate(-116.566 0)"
        fill="#e535ab"
      />
    </g>
  </svg>
)
