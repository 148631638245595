import React from 'react'
import { H1, P1 } from '../components/typo'
import ContactForm from '../components/contactForm'
import ValuationAgreement from '../components/valuationAgreement'
import { s } from '../style'

const { contactSection } = require('../config')

const ContactSection = ({
  theme,
  language,
  formData,
  setFormData,
  agreementState,
  setAgreementState,
}) => {
  const { name, description } = contactSection

  return (
    <section>
      <H1 extraCss={sH1}>{name[language]}</H1>
      {description[language] && <P1 nocenter>{description[language]}</P1>}
      <div css={{ paddingRight: '2rem' }}>
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          language={language}
          theme={theme}
        />
      </div>
      <div
        css={{
          margin: '4rem 0',
          [s.sm_down]: { margin: '2rem 0 3rem' },
          div: { margin: 0, padding: '0 2rem' },
        }}
      >
        <ValuationAgreement
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          language={language}
          theme={theme}
        />
      </div>
    </section>
  )
}

const sH1 = {
  marginBottom: '4rem',
}

export default ContactSection
