import React from 'react'
import { Hidden } from '@material-ui/core'

import IntroSection from '../sections/IntroSection'
import ProductSection from '../sections/ProductSection'
import ServicesSection from '../sections/ServicesSection'
import ValuationNeededSection from '../sections/ValuationNeededSection'
import CoopModelsSection from '../sections/CoopModelsSection'
import CoopStagesSection from '../sections/CoopStagesSection'
import TechSection from '../sections/TechSection'
import CareerPage from './CareerPage'

const IndexPage = ({ theme, language }) => {
  return (
    <>
      <IntroSection language={language} theme={theme} />
      <ProductSection language={language} theme={theme} />
      <ServicesSection language={language} theme={theme} h2 />
      <ValuationNeededSection language={language} theme={theme} />
      <CoopModelsSection language={language} theme={theme} h2 />
      <Hidden smDown>
        <CoopStagesSection language={language} theme={theme} h2 />
      </Hidden>
      <TechSection language={language} theme={theme} h2 />
      <CareerPage language={language} theme={theme} h2 />
    </>
  )
}

export default IndexPage
