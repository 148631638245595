import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { s, colors } from '../style'

const { contactForm } = require('../config')

const ValudationAgreement = ({
  language,
  agreementState,
  setAgreementState,
}) => {
  return (
    <div css={style} onClick={() => setAgreementState(!agreementState)}>
      <Checkbox
        onChange={(event) => setAgreementState(event.target.checked)}
        checked={agreementState}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
        css={{ maxHeight: 'max-content' }}
      />
      <p css={[sPara, agreementState && sPara.active]}>
        {contactForm.agreement[language]}
      </p>
    </div>
  )
}

const style = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  [s.sm_down]: { margin: '0 0.5rem' },
  [s.md]: { margin: '0 4rem' },
  span: { height: 'max-content' },
}

const sPara = {
  paddingLeft: '1rem',
  transition: 'opacity 0.3s ease-out, color 0.3s ease-out',
  color: colors.white,
  opacity: 0.6,
  active: {
    color: colors.yellowOrange,
    opacity: 0.6,
  },
}

export default ValudationAgreement
