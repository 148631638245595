import React from 'react'

import EmailWrapper from '../components/EmailWrapper'

const CareerPage = ({ theme, language, h2 }) => {
  return (
    <EmailWrapper
      h2={h2 ? true : false}
      career
      language={language}
      theme={theme}
    />
  )
}

export default CareerPage
