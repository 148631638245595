import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.788"
    height="55.907"
    viewBox="0 0 64.788 55.907"
  >
    <path
      id="Exclusion_3"
      d="M22.122,59.481A22.127,22.127,0,0,1,6.479,53.087a21.626,21.626,0,0,1,0-30.871A22.359,22.359,0,0,1,33.62,19L24,34.6a2,2,0,0,0,1.7,3.049h18.54a21.544,21.544,0,0,1-6.48,15.436A22.129,22.129,0,0,1,22.122,59.481Zm40.663-21.83H44.244A21.632,21.632,0,0,0,33.62,19L42.542,4.525a2,2,0,0,1,3.4,0L64.487,34.6a2,2,0,0,1-1.7,3.049Z"
      transform="translate(0 -3.574)"
      fill="#fff"
    />
  </svg>
)
