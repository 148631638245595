import React from 'react'
import { H1, H2, P1 } from '../components/typo'
import { ButtonGrey } from '../components/buttons'
import ServiceItemNew from '../components/serviceItemNew'
import { s } from '../style'
import DecorationPath from '../assets/paths/decorationPath'
import { Grid } from '@material-ui/core'

// FILES
import briefWeb from '../assets/pdf/brief-web.pdf'

const files = [
  { title: 'Brief Web', link: '', internal: true },
  {
    title: 'Breif Animacja ',
    link:
      'https://reklamadlabiznesu.pl/static/fad82866dd99c7e9b02b2072654b8c19/reklamadlabiznesu.pl-_-animacja-_-brief.pdf',
    internal: false,
  },
]

const { download } = require('../config').pages

const DownloadPage = ({ theme, language, h2 }) => {
  const { name } = download
  return (
    <>
      <DecorationPath section="service" />
      <section css={sSection}>
        <H1 h2={h2} extraCss={sHeadline}>
          {name[language]}
        </H1>
        <Grid container>
          {files.map((file, id) => (
            <Grid item xs={12} sm={6} md={4}>
              <ButtonGrey
                key={id}
                internal={file.internal}
                to={file.link}
                extraCss={{ width: '100%' }}
              >
                {file.title}
              </ButtonGrey>
            </Grid>
          ))}
        </Grid>
      </section>
    </>
  )
}

const sSection = {
  position: 'relative',
  [s.sm_down]: { paddingTop: '4rem' },
  // [s.md]: { marginBottom: 0, paddingBottom: '20rem'}
  [s.md]: { paddingTop: '10rem', paddingBottom: '10rem' },
}

const sHeadline = {
  textAlign: 'center',
  [s.md]: {
    textAlign: 'center',
  },
  paddingBottom: '4rem !important',
}

export default DownloadPage
