import React from 'react'
import { Link } from 'gatsby'
import { ButtonBase } from '@material-ui/core'
import { colors, s, alpha } from '../style'

const buttonContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '2rem 0',
}

const sGlowing = {
  animation: 'glowingYellow 2s infinite',
  '@keyframes glowingYellow': {
    '0%': { boxShadow: `0 4px -16px ${colors.yellowOrange}` },
    '40%': { boxShadow: `0 4px 32px ${colors.yellowOrange}` },
    '60%': { boxShadow: `0 4px 32px ${colors.yellowOrange}` },
    '100%': { boxShadow: `0 4px -16px ${colors.yellowOrange}` },
  },
}

const sButtonBase = {
  height: 'max-content',
  borderRadius: '1.5rem',
  fontFamily: 'Quicksand',
  transition:
    'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, filter 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
  },
}

export const ButtonMain = ({
  children,
  to,
  internal,
  action,
  extraCss,
  glowing,
}) => {
  if (internal)
    return (
      <div css={[buttonContainer, extraCss]}>
        <ButtonBase css={sButtonBase}>
          <Link to={to} css={[sButtonMain, glowing && sGlowing]}>
            {children}
          </Link>
        </ButtonBase>
      </div>
    )

  if (action)
    return (
      <div css={[buttonContainer, extraCss]}>
        <ButtonBase css={sButtonBase}>
          <div css={sButtonMain}>{children}</div>
        </ButtonBase>
      </div>
    )

  return (
    <div css={[buttonContainer, extraCss]}>
      <ButtonBase css={sButtonBase}>
        <a href={to} css={sButtonMain}>
          {children}
        </a>
      </ButtonBase>
    </div>
  )
}

const sButtonMain = {
  transition: 'inherit',
  padding: '0.875rem 2rem',
  borderRadius: '1.5rem',
  background: `linear-gradient(80deg, ${colors.yellow}, ${colors.orange})`,
  textAlign: 'center',
  fontSize: '1rem',
  color: colors.white.concat(alpha[100]),
  boxShadow: `0px 4px 16px ${colors.orange.concat(alpha[100])}`,
  fontWeight: 700,
  '&:hover': {
    boxShadow: `0px 6px 32px ${colors.yellowOrange}`,
    filter: 'brightness(100%) contrast(110%)',
  },
}

export const ButtonGrey = ({
  children,
  to,
  internal,
  action,
  extraCss,
  animate,
}) => {
  if (internal)
    return (
      <div css={[buttonContainer, extraCss]}>
        <ButtonBase css={sButtonBase}>
          <Link to={to} css={[sButtonGrey, animate && sAnimate]}>
            {children}
          </Link>
        </ButtonBase>
      </div>
    )

  if (action)
    return (
      <div css={[buttonContainer, extraCss]}>
        <ButtonBase css={sButtonBase}>
          <div css={sButtonGrey}>{children}</div>
        </ButtonBase>
      </div>
    )

  return (
    <div css={[buttonContainer, extraCss]}>
      <ButtonBase css={sButtonBase}>
        <a href={to} css={sButtonGrey}>
          {children}
        </a>
      </ButtonBase>
    </div>
  )
}

const sButtonGrey = {
  transition: 'inherit',
  width: 'max-content',
  padding: '0.875rem 2rem',
  borderRadius: '1.5rem',
  background: `linear-gradient(135deg, ${colors.background1.concat(
    alpha[60]
  )}, ${colors.white.concat(alpha[60])} 70%)`,
  textAlign: 'center',
  fontSize: '1rem',
  color: colors.white.concat(alpha[60]),
  boxShadow: `0px 4px 48px ${colors.white.concat(alpha[30])}`,
  fontWeight: 700,
  '&:hover': {
    color: colors.white.concat(alpha[100]),
    boxShadow: `0px 6px 48px ${colors.white.concat(alpha[60])}`,
    filter: 'brightness(120%) contrast(110%)',
  },
}

const sAnimate = {
  animation: 'slideUp 0.4s 2.0s ease-out both ',
  '@keyframes slideUp': {
    from: { opacity: 0, transform: 'translateZ(-1400px)' },
    to: { opacity: 1, transform: 'translateZ(0px)' },
  },
}

export const ButtonReactive = ({
  children,
  extraCss,
  active,
  animate,
  onClickFunction,
}) => {
  return (
    <div css={[buttonContainer, extraCss]} onClick={onClickFunction}>
      <ButtonBase css={sButtonBase}>
        <div css={[sButtonReactive, active && sButtonReactive.active]}>
          {children}
        </div>
      </ButtonBase>
    </div>
  )
}

const sButtonReactive = {
  transition: 'inherit',
  width: 'max-content',
  padding: '0.875rem 2rem',
  borderRadius: '1.5rem',
  backgroundColor: 'transparent',
  textAlign: 'center',
  fontSize: '1rem',
  color: colors.white.concat(alpha[30]),
  textTransform: 'uppercase',
  // boxShadow: `0px 0px 0px ${colors.white.concat(alpha[30])}`,
  boxShadow: `inset 0px 0px 0px 4px ${colors.white.concat(alpha[16])}`,
  fontWeight: 700,
  '&:hover': {
    color: colors.white.concat(alpha[100]),
    backgroundColor: colors.white.concat(alpha[16]),
    filter: 'brightness(120%) contrast(110%)',
  },

  active: {
    backgroundColor: colors.yellowOrange,
    color: colors.white,
    boxShadow: `0px 4px 16px ${colors.orange.concat(alpha[100])}`,
    '&:hover': {
      color: colors.white.concat(alpha[100]),
      backgroundColor: colors.yellowOrange,
      filter: 'brightness(110%) contrast(110%)',
    },
  },
}
