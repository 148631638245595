import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="38"
    viewBox="0 0 48 38"
  >
    <path
      id="code-solid"
      d="M20.893,38l-4.575-1.314A.891.891,0,0,1,15.7,35.58L25.94.68A.9.9,0,0,1,27.057.072l4.575,1.314a.891.891,0,0,1,.615,1.106L22.01,37.392A.9.9,0,0,1,20.893,38Zm-8.549-8.328,3.262-3.444a.884.884,0,0,0-.06-1.277L8.751,19.036l6.794-5.916a.878.878,0,0,0,.06-1.277L12.343,8.4a.907.907,0,0,0-1.275-.037L.262,18.383a.879.879,0,0,0,0,1.3L11.069,29.709a.9.9,0,0,0,1.275-.037Zm24.538.045L47.688,19.689a.879.879,0,0,0,0-1.3L36.881,8.355a.915.915,0,0,0-1.275.037l-3.262,3.444a.884.884,0,0,0,.06,1.277L39.2,19.036,32.4,24.952a.878.878,0,0,0-.06,1.277l3.262,3.444a.908.908,0,0,0,1.275.045Z"
      transform="translate(0.025 -0.037)"
      fill="#fff"
    />
  </svg>
)
