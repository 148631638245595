import React from 'react'
import TextWithGlow from '../components/textWithGlow'
import { H1, H2, P1 } from '../components/typo'
import { ButtonMain, ButtonGrey } from '../components/buttons'
import { s, colors, alpha } from '../style'
import DecorationPath from '../assets/paths/decorationPath'
import { Icons } from '../components/serviceItem'

const { aboutSection } = require('../config')

const AboutItem = ({ text, icon, extraCss, description }) => {
  return (
    <div css={[sContainer, extraCss]}>
      <div css={sIcon} className="sIcon">
        {Icons[icon]}
      </div>
      <h4 css={sText} className="sText">
        {text}
      </h4>
      <P1 extraCss={sDescription}>{description}</P1>
    </div>
  )
}

const AboutSection = ({ theme, language }) => {
  const { name, description, sections } = aboutSection

  return (
    <section>
      <H1 extraCss={sH1}>{name[language]}</H1>
      {description && <P1>{description[language]}</P1>}
      {sections.map((section, id) => (
        <section key={id} css={sSection} id={section.hash}>
          <DecorationPath section="about" />
          <H2>
            <TextWithGlow>{section.title[language]}</TextWithGlow>
          </H2>
          <P1>{section.text[language]}</P1>
          <div css={sItems}>
            {section.itemsLarge
              ?.sort((a, b) => a.id - b.id)
              .map((item, id) => (
                <AboutItem
                  key={id}
                  text={item.title[language]}
                  icon={item.icon}
                  description={item.text[language]}
                />
              ))}
          </div>
          {section.button && (
            <ButtonGrey
              internal={section.button.link.internal}
              to={section.button.link.to}
              extraCss={sButton}
            >
              {section.button.text[language]}
            </ButtonGrey>
          )}
        </section>
      ))}
    </section>
  )
}

const sH1 = {
  marginBottom: '4rem',
}

const sSection = {
  [s.sm_down]: { paddingTop: '4rem' },
  // [s.md]: { marginBottom: 0, paddingBottom: '20rem'}
  [s.md]: { paddingTop: '10rem', paddingBottom: '10rem' },
  position: 'relative',
}

const sItems = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  [s.sm_down]: { padding: '0 2rem' },
  [s.md]: { padding: '0 2rem' },
}

const sButton = {
  [s.md]: { marginTop: '4rem' },
}

const sContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '6rem',
}
const sIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 128,
  height: 128,
  borderRadius: 64,
  background: `linear-gradient(to bottom, ${colors.yellow}, ${colors.orange})`,
  boxShadow: `0px 0px 24px ${colors.orange.concat(alpha[60])}`,
}
const sText = {
  padding: '2rem 0',
  fontSize: '1.5rem',
  color: colors.yellowOrange,
  fontWeight: 700,
  textTransform: 'uppercase',
  textShadow: `0px 0px 16px ${colors.orange}`,
}

const sDescription = {
  [s.md]: {
    maxWidth: '75%',
  },
}

export default AboutSection
