import React from 'react'

import { s, alpha, colors } from '../style'

import light from '../assets/icons/light'
import document from '../assets/icons/document'
import documents from '../assets/icons/documents'
import pictures from '../assets/icons/pictures'
import prototype from '../assets/icons/prototype'
import code from '../assets/icons/code'
import reactjs from '../assets/icons/reactjs'
import graphql from '../assets/icons/graphql'
import pwa from '../assets/icons/pwa'
import gatsbyjs from '../assets/icons/gatsbyjs'
import nodejs from '../assets/icons/nodejs'
import wordpress from '../assets/icons/wordpress'
import adobexd from '../assets/icons/adobexd'
import svg from '../assets/icons/svg'
import materialdesign from '../assets/icons/materialdesign'
import apollo from '../assets/icons/apollo'

export const Icons = {
  light,
  document,
  documents,
  pictures,
  prototype,
  code,
  reactjs,
  graphql,
  pwa,
  gatsbyjs,
  nodejs,
  wordpress,
  adobexd,
  svg,
  materialdesign,
  apollo,
}

const serviceItem = ({ text, icon, extraCss }) => {
  return (
    <div css={[sContainer, extraCss]}>
      <div css={sIcon} className="sIcon">
        {Icons[icon]}
      </div>
      <h4 css={sText} className="sText">
        {text}
      </h4>
    </div>
  )
}

export const sContainer = {
  margin: '2rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    '.sIcon': {
      svg: {
        filter: 'brightness(100%) contrast(100%) grayscale(0)',
        opacity: 1,
      },
      backgroundColor: colors.white.concat(alpha[16]),
      boxShadow: `0px 4px 24px ${colors.black.concat(alpha[8])}`,
    },
    '.sText': {
      color: colors.white.concat(alpha[100]),
    },
  },
}

export const sIcon = {
  flexShrink: 0,
  transition:
    'background-color 0.2s ease-in, background 0.3s ease-in-out, opacity 0.3s ease-in-out, filter 0.3s ease-in-out',
  width: 80,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 48,
  backgroundColor: colors.white.concat(alpha[8]),
  svg: {
    maxHeight: 40,
    maxWidth: 40,
    transition:
      'opacity 0.2s ease-in, filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    filter: 'brightness(1000%) contrast(50%) grayscale(1)',
    opacity: 0.6,
    width: 40,
    height: 'auto',
  },
}

const sText = {
  transition: 'all 0.2s ease-in, font-weight 0.3s ease-out',
  fontSize: '1rem',
  paddingTop: '1rem',
  fontWeight: 400,
  maxWidth: 96,
  textAlign: 'center',
  color: colors.white.concat(alpha[30]),
}

export default serviceItem
