import React from 'react'
import { H1, P1 } from '../components/typo'
import { s, colors, alpha } from '../style'
import { Grid } from '@material-ui/core'
import ServiceItemNew from '../components/serviceItemNew'
import { Icons, sContainer, sIcon, sText } from '../components/serviceItem'

const { techSection } = require('../config')

const TechSection = ({ theme, language, h2 }) => {
  const { name, description, hash, techs } = techSection

  return (
    <section id={hash}>
      <H1 h2={h2} extraCss={sH1}>
        {name[language]}
      </H1>
      {description[language] && <P1 nocenter>{description[language]}</P1>}
      <Grid container css={{ margin: '0 2rem' }}>
        {techs
          ?.sort((a, b) => a.id - b.id)
          .map((tech, id) => (
            <Grid item xs={12} md={6} key={id}>
              <ServiceItemNew
                iconColors
                language={language}
                title={tech.name}
                description={tech.description[language]}
                icon={tech.icon}
                iconStyle={sTechIcon}
              />
            </Grid>
          ))}
      </Grid>
    </section>
  )
}

const sH1 = {
  marginBottom: '4rem',
  [s.md]: { paddingBottom: '4rem', marginBottom: 0 },
}

const sTechIcon = {
  // svg: { 'path, rect': { fill: 'initial' } },
}

// const sContainerOverride = {
//   flexDirection: 'row',
//   justifyContent: 'flex-start',
//   alignItems: 'flex-start',
//   textAlign: 'left',
//   margin: 0,
//   padding: '0rem 2rem 4rem',
//   [s.md]: { padding: '0 4rem 4rem 2rem' },
// }

// const sIconOR = {
//   width: 64,
//   height: 64,
//   svg: {
//     width: 32,
//     height: 'auto',
//   },
// }

// const sTextOR = {
//   fontSize: '1rem',
//   color: colors.white.concat(alpha[60]),
//   marginBottom: '0.5rem',
// }

// const sTextContainer = {
//   display: 'flex',
//   flexDirection: 'column',
//   paddingLeft: '1rem',
// }

// const sDescription = {
//   fontSize: '0.875rem',
//   color: colors.white.concat(alpha[60]),
// }

export default TechSection

/* <Grid container>
        {techs
          .sort((a, b) => a.id - b.id)
          .map((tech, id) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              key={id}
              css={[sContainer, sContainerOverride]}
            >
              <div css={[sIcon, sIconOR]} className="sIcon">
                {Icons[tech.icon]}
              </div>
              <div css={sTextContainer}>
                <h6 css={[sText, sTextOR]} className="sText">
                  {tech.name}
                </h6>
                <p css={sDescription}>{tech.description[language]}</p>
              </div>
            </Grid>
          ))}
</Grid> */
