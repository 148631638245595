import React, { useState, useEffect, useCallback, useContext } from 'react'
import ValuationSection from '../sections/ValuationSection'
import ContactSection from '../sections/ContactSection'
import CareerSection from '../sections/CareerSection'
import { ButtonReactive } from '../components/buttons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import { P1 } from './typo'
import * as emailjs from 'emailjs-com'
import { colors, alpha, s } from '../style'
import { StateContext } from '../context'

const { contactForm } = require('../config')

const EmailWrapper = ({ theme, language, contact, valuation, career, h2 }) => {
  const initialFormData = {
    name: '',
    phone: '',
    email: '',
    message: '',
  }

  const emailjsconfig = {
    service: 'appclevergmail',
    template: valuation ? 'valuationTemplate' : 'contactTemplate',
    emailjsid: 'user_JgFeYofxKQFdI31oulaFR',
  }

  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedElements, setSelectedElements] = useState([])
  const [formData, setFormData] = useState(initialFormData)
  const [budgetState, setBudgetState] = useState(0)
  const [agreementState, setAgreementState] = useState(false)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const state = useContext(StateContext)

  const formFilled =
    Object.values(formData).filter((value) => value).length ===
    Object.keys(formData).length

  const templateParams = {
    ID: Math.random().toString(36).substring(7).toUpperCase(),
    TIME: new Date().toLocaleString('pl'),
    TOEMAIL: 'pp@appclever.pl',
    FROMNAME: 'AppClever',
    FROMEMAIL: '',
    REPLYTO: '',
    CC: '',
    BCC: '',

    MESSAGE_TYPE: contact
      ? 'kontakt'
      : valuation
      ? 'wycena'
      : career
      ? 'kariera'
      : '?',

    CATEGORIES: selectedCategories,
    ELEMENTS: selectedElements,
    BUDGET: budgetState,
    ...formData,
  }

  const validate = useCallback(() => {
    if (valuation)
      return (
        formFilled &&
        agreementState &&
        budgetState &&
        selectedCategories.length > 0 &&
        selectedElements.length > 0
      )
    else return formFilled && agreementState
  }, [
    formFilled,
    agreementState,
    budgetState,
    selectedCategories,
    selectedElements,
  ])

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  // FIX ME LATER
  // useEffect(() => {
  //   console.log('selectedCategories', selectedCategories.length)
  //   console.log('state', state)
  //   console.log('selectedOptions', state.selectedOption)
  //   console.log('selectedCategories', selectedCategories)
  //   if (selectedCategories.length < 0) {
  //     if (state && state.selectedOption) {
  //       setSelectedCategories(['xd'])
  //       console.log('selectedCategories', selectedCategories)
  //     }
  //   }
  // }, [state.selectedOption])

  const onSendForm = async () => {
    console.log('clicked')
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      try {
        let result = await emailjs.send(
          emailjsconfig.service,
          emailjsconfig.template,
          templateParams,
          emailjsconfig.emailjsid
        )
        if (result) {
          setSuccess(true)
          setOpenBackdrop(true)
          setFormData(initialFormData)
          setAgreementState(false)
          setBudgetState(0)
          setSelectedCategories([])
          setSelectedElements([])
          console.log(result)
        }
      } catch (error) {
        setOpenBackdrop(true)
        setSuccess(false)
        console.log(error)
      }
    }
  }

  return (
    <>
      {valuation && (
        <ValuationSection
          language={language}
          theme={theme}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedElements={selectedElements}
          setSelectedElements={setSelectedElements}
          formData={formData}
          setFormData={setFormData}
          budgetState={budgetState}
          setBudgetState={setBudgetState}
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          formFilled={formFilled}
        />
      )}
      {contact && (
        <ContactSection
          language={language}
          theme={theme}
          formData={formData}
          setFormData={setFormData}
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          formFilled={formFilled}
        />
      )}
      {career && (
        <CareerSection
          h2={h2}
          language={language}
          theme={theme}
          formData={formData}
          setFormData={setFormData}
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          formFilled={formFilled}
        />
      )}
      <ButtonReactive
        onClickFunction={onSendForm}
        active={mailState === 'filled'}
        extraCss={{ paddingBottom: '4rem' }}
      >
        {mailState === 'unfilled' ? (
          contactForm.validation[language]
        ) : mailState === 'filled' ? (
          contactForm.button.default[language]
        ) : (
          <CircularProgress />
        )}
      </ButtonReactive>
      <Backdrop
        css={sBackdrop}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        {success === null && <CircularProgress color="inherit" />}
        {success === true && (
          <div css={[sResponse, sResponse.success]}>
            <p className="response-title">
              {contactForm.success.headline[language]}
            </p>
            <p css={sResponse.description}>
              {contactForm.success.description[language]}
            </p>
          </div>
        )}
        {success === false && (
          <div css={[sResponse, sResponse.failure]}>
            <p className="response-title">
              {contactForm.failed.headline[language]}
            </p>
            <p css={sResponse.description}>
              {contactForm.failed.description[language]}
            </p>
          </div>
        )}
      </Backdrop>
    </>
  )
}

const sBackdrop = {
  zIndex: 1010,
  // backgroundColor: colors.black.concat(alpha[90]),
  backdropFilter: 'blur(16px)',
}

const sResponse = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.response-title': {
    fontSize: '3rem',
    paddingBottom: '2rem',
    color: colors.yellowOrange,
    textShadow: `0px 0px 16px ${colors.orange}`,
    fontWeight: 700,
  },
  description: {
    fontSize: '1rem',
    color: colors.white.concat(alpha[60]),
    padding: '0 2rem',
  },
}

export default EmailWrapper
