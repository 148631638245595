import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="64"
    viewBox="0 0 58 64"
  >
    <g id="nodejs-icon" transform="translate(-0.603 -0.584)">
      <path
        id="Path_69"
        d="M26.986,1.267a5.354,5.354,0,0,1,5.232,0q11.949,6.761,23.9,13.517A4.927,4.927,0,0,1,58.6,19.028V46.145a4.981,4.981,0,0,1-2.67,4.335Q44.023,57.2,32.118,63.933a5.2,5.2,0,0,1-5.29-.147q-3.571-2.073-7.145-4.137a4.207,4.207,0,0,1-1.378-.994c.3-.41.847-.461,1.288-.64A16.667,16.667,0,0,0,22.411,56.7a.657.657,0,0,1,.734.044c2.036,1.169,4.053,2.371,6.1,3.529.436.252.877-.083,1.249-.29q11.687-6.614,23.383-13.213A.775.775,0,0,0,54.3,46q.008-13.418,0-26.838a.839.839,0,0,0-.5-.837Q41.932,11.637,30.069,4.936a.823.823,0,0,0-.932,0q-11.866,6.7-23.729,13.4a.829.829,0,0,0-.5.833q0,13.418,0,26.839a.759.759,0,0,0,.43.753q3.167,1.8,6.337,3.584a4.891,4.891,0,0,0,3.962.531,3.01,3.01,0,0,0,1.946-2.828c.011-8.893-.005-17.788.008-26.68a.669.669,0,0,1,.728-.684c1.014-.007,2.03-.014,3.044,0a.7.7,0,0,1,.662.813c0,8.95.011,17.9-.007,26.849,0,2.385-.976,4.98-3.179,6.147a9.779,9.779,0,0,1-8.751-.241c-2.321-1.16-4.537-2.529-6.817-3.772A4.969,4.969,0,0,1,.6,46.145V19.028a4.936,4.936,0,0,1,2.57-4.3Q15.081,8,26.986,1.267Z"
        transform="translate(0 0)"
        fill="#6c3"
      />
      <path
        id="Path_70"
        d="M114.346,85.69c3.452-.222,7.148-.132,10.255,1.569a7.755,7.755,0,0,1,3.781,6.711.726.726,0,0,1-.788.535c-1,0-2,.014-3.005-.007a.774.774,0,0,1-.725-.751,4.575,4.575,0,0,0-2.188-3.162c-1.847-.925-3.989-.878-6-.859a8.15,8.15,0,0,0-4.3,1.07,2.723,2.723,0,0,0-.906,3.042c.322.765,1.2,1.011,1.927,1.239,4.16,1.088,8.569.98,12.65,2.412a5.956,5.956,0,0,1,3.921,3.488,7.451,7.451,0,0,1-1.262,7.108,9.961,9.961,0,0,1-5.347,2.882,25.691,25.691,0,0,1-8.072.343,12.72,12.72,0,0,1-7.1-2.671,7.451,7.451,0,0,1-2.4-5.877.673.673,0,0,1,.737-.594c1.009-.008,2.017-.011,3.026,0a.707.707,0,0,1,.722.7,4.585,4.585,0,0,0,1.706,3.219c2.05,1.323,4.622,1.232,6.97,1.269,1.945-.086,4.128-.112,5.715-1.4a3.156,3.156,0,0,0,.859-3.015c-.245-.891-1.177-1.306-1.977-1.577-4.107-1.3-8.565-.828-12.632-2.3a6.148,6.148,0,0,1-3.882-3.383,6.93,6.93,0,0,1,1.384-7.21,11.171,11.171,0,0,1,6.934-2.786Z"
        transform="translate(-80.411 -65.583)"
        fill="#6c3"
      />
    </g>
  </svg>
)
