import React from 'react'
import { H2 } from '../components/typo'
import { ButtonMain } from '../components/buttons'
import TextWithGlow from '../components/textWithGlow'

const { valuationNeededSection } = require('../config')

const ValuationNeededSection = ({ theme, language }) => {
  const { title, button } = valuationNeededSection
  return (
    <div css={style}>
      <H2>
        <TextWithGlow>{title[language]}</TextWithGlow>
      </H2>
      <ButtonMain internal={button.internal} to={button.to} glowing>
        {button.text[language]}
      </ButtonMain>
    </div>
  )
}

const style = {
  margin: '8rem 0',
}

export default ValuationNeededSection
