import React from 'react'
import { colors, alpha, s } from '../style'

export const H1 = ({ children, h2, extraCss }) =>
  h2 ? (
    <h2 css={[sH1, sH1butH2, extraCss]}>{children}</h2>
  ) : (
    <h1 css={[sH1, extraCss]}>{children}</h1>
  )

const sH1 = {
  fontWeight: 300,
  [s.sm_down]: {
    fontSize: '3rem',
    padding: '4rem 3rem 0rem',
    textAlign: 'center',
  },
  [s.ss]: { fontSize: '2.25rem' },
  [s.md]: { fontSize: '3rem', padding: '8rem 2rem 0rem', textAlign: 'left' },
  lineHeight: '1.2em',

  textTransform: 'uppercase',
}

const sH1butH2 = {
  color: colors.white.concat(alpha[30]),
  [s.md]: { marginBottom: '8rem' },
}

export const H2 = ({ children, extraCss }) => (
  <h2 css={[sH2, extraCss]}>{children}</h2>
)

const sH2 = {
  fontWeight: 300,
  fontSize: '2rem',
  lineHeight: '1.2em',
  textAlign: 'center',
  padding: '4rem 1rem 3rem',
  color: colors.white.concat(alpha[75]),
}

export const P1 = ({ children, nocenter, extraCss }) => (
  <p
    css={[
      sP1,
      nocenter ? { textAlign: 'left' } : { textAlign: 'center' },
      extraCss,
    ]}
  >
    {children}
  </p>
)

const sP1 = {
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.618em',
  textAlign: 'center',
  padding: '0rem 2rem 4rem',
  color: colors.white.concat(alpha[60]),
}
