import React from 'react'

import TextWithGlow from '../components/textWithGlow'
import { ButtonMain } from '../components/buttons'
import { H1, P1 } from '../components/typo'
import { s, colors, alpha } from '../style'

import { Hidden } from '@material-ui/core'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import PersonIcon from '@material-ui/icons/Person'
import GroupIcon from '@material-ui/icons/Group'

import DecorationPath from '../assets/paths/decorationPath'

const { coopModelsSection } = require('../config')

const Icons = {
  accessability: <AccessibilityNewIcon />,
  people: <GroupIcon />,
  person: <PersonIcon />,
}

const CoopModelsSection = ({ theme, language, h2 }) => {
  const { name, description, hash, list, button } = coopModelsSection
  return (
    <section id={hash}>
      <H1 h2={h2} extraCss={sH1}>
        {name[language]}
      </H1>
      {description[language] && <P1>{description[language]}</P1>}
      <div css={sContainer}>
        {list.map((para, id) => (
          <div key={id} css={sItemContainer}>
            {id % 2 !== 0 && <DecorationPath section="coop" />}
            <div css={sTitleContainer}>
              <div css={sIcon}>{Icons[para.icon]}</div>
              <h3 css={sTitle}>
                <TextWithGlow>{para.title[language]}</TextWithGlow>
              </h3>
            </div>
            <P1 nocenter extraCss={sDescription}>
              {para.text[language]}
            </P1>
          </div>
        ))}
      </div>
      {h2 && (
        <Hidden mdUp>
          <ButtonMain
            internal={button.link.internal}
            to={button.link.to}
            extraCss={sButton}
          >
            {button.text[language]}
          </ButtonMain>
        </Hidden>
      )}
    </section>
  )
}

const sH1 = { marginBottom: '4rem' }

const sContainer = {
  display: 'flex',
  [s.sm_down]: { flexDirection: 'column' },
  [s.md]: { flexDirection: 'column' },
}

const sItemContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  [s.md]: {
    marginBottom: '4rem',
  },
}

const sTitleContainer = {
  display: 'flex',
  alignItems: 'center',
  margin: '0rem 1rem 2rem 2rem',
  [s.md]: {
    // width: '75%',
    margin: '0 2rem 2rem',
  },
}

const sIcon = {
  flexShrink: 0,
  width: 64,
  height: 64,
  background: `linear-gradient(${colors.yellow}, ${colors.orange})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  boxShadow: `0px 0px 16px ${colors.orange}`,
  svg: {
    width: 30,
    height: 'auto',
  },
}

const sTitle = {
  marginLeft: '1.5rem',
  color: colors.white.concat(alpha[75]),
  fontSize: '1.5rem',
  [s.sm_down]: { fontSize: '1.25rem' },
  [s.ss]: { fontSize: '1rem' },
}

const sDescription = {
  [s.md]: {
    padding: '0rem 2rem 4rem',
    // width: '75%',
  },
}

const sButton = {
  marginTop: 0,
}

export default CoopModelsSection
