import React from 'react'
import ServiceItem from '../components/serviceItem'
import { H2 } from '../components/typo'

import { s, colors, alpha } from '../style'
import { Grid } from '@material-ui/core'

const ValuationPicker = ({
  selectedCategories,
  setSelectedCategories,
  selectedElements,
  setSelectedElements,
  formSections,
  language,
  children,
  specificID,
  filledWithData,
}) => {
  const isActiveStyle = (formsectionkey) => {
    if (formsectionkey === 'categories' && selectedCategories.length > 0)
      return true
    if (formsectionkey === 'elements' && selectedElements.length > 0)
      return true
    else return false
  }

  const isSelectedItem = (optionkey) =>
    selectedCategories.includes(optionkey) ||
    selectedElements.includes(optionkey)

  if (specificID)
    return (
      <>
        {Object.entries(formSections)
          .filter((a, id) => id === specificID)
          .map(([formsectionkey, formsection], id) => (
            <div key={id} css={sFormSectionContainer}>
              <div css={sHeadlineContainer}>
                <div css={[sBall, filledWithData && sBall.active]}>
                  {formsection.number}
                </div>
                <H2 extraCss={[sH2, filledWithData && sH2.active]}>
                  {formsection.title[language]}
                </H2>
              </div>
              <div css={[sLine, filledWithData && sLine.active]}></div>
              {children && <div css={sOptions}>{children}</div>}
            </div>
          ))}
      </>
    )

  return (
    <>
      {Object.entries(formSections)
        .filter((a, id) => id < 2)
        .map(([formsectionkey, formsection], id) => (
          <div key={id} css={sFormSectionContainer}>
            <div css={sHeadlineContainer}>
              <div css={[sBall, isActiveStyle(formsectionkey) && sBall.active]}>
                {formsection.number}
              </div>
              <H2 extraCss={[sH2, isActiveStyle(formsectionkey) && sH2.active]}>
                {formsection.title[language]}
              </H2>
            </div>
            <div
              css={[sLine, isActiveStyle(formsectionkey) && sLine.active]}
            ></div>
            {children ? (
              <div css={sOptions}>{children}</div>
            ) : (
              <Grid container css={sOptions}>
                {formsection.options &&
                  Object.entries(formsection.options).map(
                    ([optionkey, option], id) => (
                      <Grid
                        item
                        xs={6}
                        md={3}
                        key={id}
                        onClick={() => {
                          if (formsectionkey === 'categories') {
                            if (selectedCategories.includes(optionkey))
                              return setSelectedCategories(
                                selectedCategories.filter(
                                  (e) => e !== optionkey
                                )
                              )
                            else
                              return setSelectedCategories(
                                selectedCategories.concat(optionkey)
                              )
                          } else if (formsectionkey === 'elements') {
                            if (selectedElements.includes(optionkey))
                              return setSelectedElements(
                                selectedElements.filter((e) => e !== optionkey)
                              )
                            else
                              return setSelectedElements(
                                selectedElements.concat(optionkey)
                              )
                          }
                        }}
                      >
                        <ServiceItem
                          text={option.name[language]}
                          icon={option.icon}
                          extraCss={[
                            sItemOR,
                            isSelectedItem(optionkey) && sSelectedItem,
                          ]}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            )}
          </div>
        ))}
    </>
  )
}

const sFormSectionContainer = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}

const sHeadlineContainer = {
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 0rem 1rem 2rem',
  width: '100%',
}

const sBall = {
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 700,
  borderRadius: 12,
  color: colors.white.concat(alpha[100]),
  background: `linear-gradient(${colors.white.concat(
    alpha[30]
  )}, ${colors.white.concat(alpha[16])})`,
  boxShadow: `0px 0px 16px ${colors.white.concat(alpha[30])}`,

  active: {
    color: colors.white.concat(alpha[100]),
    background: `linear-gradient(${colors.yellow}, ${colors.orange})`,
    boxShadow: `0px 0px 16px ${colors.orange.concat(alpha[100])}`,
  },
}

const sH2 = {
  transition: 'color 0.3s ease-in-out',
  padding: 0,
  paddingLeft: '1.5rem',
  fontSize: '1.5rem',
  fontWeight: 400,
  color: colors.white.concat(alpha[100]),

  active: {
    color: colors.white.concat(alpha[30]),
  },
}

const sLine = {
  transition: 'opacity 0.3s ease-out, filter 0.3s ease-out',
  position: 'absolute',
  left: 42,
  top: 56,
  width: 4,
  height: 'calc(100% - 56px)',
  background: `linear-gradient(${colors.orange.concat(alpha[60])}, ${
    colors.yellow
  })`,
  boxShadow: `0px 0px 8px ${colors.orange}`,
  borderRadius: 2,
  opacity: 0.16,
  filter: 'grayscale(1)',

  active: {
    opacity: 1,
    filter: 'grayscale(0)',
  },
}

const sOptions = {
  padding: '2rem 3rem',
}

const sItemOR = {
  cursor: 'pointer',
  '.sText': {
    fontWeight: 700,
  },
  '.sIcon': {
    'svg path, svg rect': { fill: colors.white },
  },
  '&:hover': {
    '.sText': {
      color: colors.white.concat(alpha[60]),
    },
    [s.touch]: {
      '.sIcon': {
        svg: { opacity: 0.4 },
        backgroundColor: colors.white.concat(alpha[8]),
        boxShadow: `0px 4px 24px ${colors.black.concat(alpha[0])}`,
      },
      '.sText': {
        color: colors.white.concat(alpha[30]),
      },
    },
  },
}

const sSelectedItem = {
  '.sIcon': {
    backgroundColor: colors.yellowOrange,
    boxShadow: `0px 0px 24px ${colors.orange.concat(alpha[100])}`,
    'svg path, svg rect': { fill: colors.white, opacity: 1 },
    svg: { filter: 'none', opacity: 1 },
  },
  '&:hover': {
    [s.hover]: {
      // '.sIcon': {
      //   backgroundColor: colors.white.concat(alpha[30]),
      //   'svg path, svg rect': { fill: colors.white, opacity: 1 },
      //   boxShadow: `0px 0px 24px ${colors.white.concat(alpha[30])}`,
      // },
      '.sIcon': {
        backgroundColor: colors.yellowOrange,
        boxShadow: `0px 0px 24px ${colors.orange.concat(alpha[100])}`,
        'svg path, svg rect': { fill: colors.white, opacity: 1 },
        svg: { filter: 'none', opacity: 1 },
        filter: 'brightness(1.1)',
      },
      '.sText': {
        color: colors.yellow.concat(alpha[100]),
        filter: 'brightness(1.1)',
      },
    },
    [s.touch]: {
      '.sIcon': {
        opacity: 1,
        backgroundColor: colors.yellowOrange,
        boxShadow: `0px 0px 24px ${colors.orange.concat(alpha[100])}`,
        'svg path, svg rect': { fill: colors.white, opacity: 1 },
        svg: { filter: 'none', opacity: 1 },
      },
      '.sText': {
        color: colors.yellow.concat(alpha[100]),
      },
    },
  },
  '.sText': {
    color: colors.yellow.concat(alpha[100]),
  },
}

export default ValuationPicker
