import React from 'react'

import { H1, H2, P1 } from '../components/typo'
import { ButtonMain } from '../components/buttons'
import { s, colors, alpha } from '../style'

import Dot from '@material-ui/icons/FiberManualRecord'
import CheckIcon from '@material-ui/icons/Check'

import DecorationPath from '../assets/paths/decorationPath'

const { coopStagesSection } = require('../config')

const CoopStagesSection = ({ theme, language, h2 }) => {
  const { name, description, hash, list, button } = coopStagesSection
  return (
    <section id={hash}>
      <H1 h2={h2} extraCss={sH1}>
        {name[language]}
      </H1>
      {description[language] && <P1>{description[language]}</P1>}
      <div css={sContainer}>
        {list.map((para, id) => (
          <div key={id} css={sItemContainer}>
            {id % 2 !== 0 && <DecorationPath section="coop" />}
            <div css={sTitleContainer}>
              <div css={sNumber}>{para.number || <CheckIcon />}</div>
              <div css={sHealdine}>
                <p css={sSubtitle}>{para.subtitle[language]}</p>
                <h5 css={sTitle}>
                  {para.title[language].map((word, id) => (
                    <span key={id}>{word}</span>
                  ))}
                </h5>
              </div>
            </div>
            {para.unorderedList[language].map((text, id) => (
              <div css={sDescriptionContainer} key={id}>
                <Dot />
                <p key={id} css={sDescription}>
                  {text}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <ButtonMain
        internal={button.link.internal}
        to={button.link.to}
        extraCss={sButton}
      >
        {button.text[language]}
      </ButtonMain>
    </section>
  )
}

const sH1 = { marginBottom: '4rem' }

const sContainer = {
  display: 'flex',
  [s.sm_down]: { flexDirection: 'column' },
  [s.md]: { flexDirection: 'column' },
}

const sItemContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '4rem',
  [s.md]: {
    marginBottom: '6rem',
  },
}

const sTitleContainer = {
  display: 'flex',
  alignItems: 'center',
  margin: '0rem 1rem 2rem 2rem',
  [s.md]: {
    // width: '75%',
    margin: '0 2rem 2rem',
  },
}

const sHealdine = {
  display: 'flex',
  marginLeft: '1.5rem',
  flexDirection: 'column',
}

const sNumber = {
  flexShrink: 0,
  width: 64,
  height: 64,
  background: `linear-gradient(${colors.yellow}, ${colors.orange})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  boxShadow: `0px 0px 16px ${colors.orange}`,
  fontSize: '2rem',
  fontWeight: 700,
  svg: {
    height: 40,
    width: 'auto',
  },
}

const sTitle = {
  color: colors.white.concat(alpha[75]),
  fontSize: '2rem',
  [s.sm_down]: { fontSize: '1.5rem' },
  [s.ss]: { fontSize: '1rem' },
}

const sSubtitle = {
  fontWeight: 300,
  color: colors.white.concat(alpha[75]),
  textTransform: 'uppercase',
  fontSize: '1.25rem',
  lineHeight: 1,
}

const sDescriptionContainer = {
  [s.md]: {
    // width: '75%',
    margin: '0 2rem 0',
  },
  padding: '0rem 2rem 2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: colors.white.concat(alpha[60]),
  svg: {
    width: 8,
    height: 8,
  },
}

const sDescription = {
  fontSize: '0.875rem',
  [s.md]: { fontSize: '1rem' },
  padding: 0,
  margin: 0,
  marginLeft: '1rem',
}

const sButton = {
  marginTop: 0,
}

export default CoopStagesSection
