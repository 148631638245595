import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63.943"
    height="63.993"
    viewBox="0 0 63.943 63.993"
  >
    <path
      id="Exclusion_2"
      d="M32.4,64v0a32.093,32.093,0,0,0,31.54-30.1A31.971,31.971,0,0,1,32.4,64Zm-3.178-.115A31.973,31.973,0,0,1,0,32.283a32.263,32.263,0,0,0,29.225,31.6ZM32,62.533A30.533,30.533,0,1,1,62.529,32,30.568,30.568,0,0,1,32,62.533Zm.48-28.231h0L24.215,58.316a27.57,27.57,0,0,0,16.927-.439,2.426,2.426,0,0,1-.2-.38L32.479,34.3ZM6.948,20.683a27.551,27.551,0,0,0,13.139,36Zm49.112-2h0a21.054,21.054,0,0,1,.186,2.831,26.008,26.008,0,0,1-2.094,9.866L45.737,55.7A27.548,27.548,0,0,0,56.06,18.678ZM18.293,16.659a1.089,1.089,0,0,1,1.117,1.063,1.118,1.118,0,0,1-1.027,1.208c-.015,0-1.518.177-3.152.263l10.03,29.834L31.289,30.95,27,19.193c-1.459-.086-2.874-.261-2.888-.263a1.05,1.05,0,0,1-1.029-1.139,1.115,1.115,0,0,1,1.12-1.133l.083,0c.045,0,4.579.35,7.256.35,2.845,0,7.3-.347,7.34-.35l.086,0a1.089,1.089,0,0,1,1.117,1.063,1.119,1.119,0,0,1-1.027,1.208c-.015,0-1.522.177-3.153.263L45.857,48.8,48.6,39.62l.209-.667.009-.029c1.091-3.481,1.879-6,1.879-8.207a14.479,14.479,0,0,0-2.272-7.6l-.32-.517c-1.281-2.067-2.385-3.849-2.385-5.942a5.12,5.12,0,0,1,1.3-3.394,4.44,4.44,0,0,1,3.327-1.5c.08,0,.158.007.234.013l.124.009A27.537,27.537,0,0,0,9.095,16.979c.716.022,1.3.033,1.772.033,2.846,0,7.3-.347,7.34-.35ZM.095,29.544h0A32.006,32.006,0,0,1,31.44,0,32.272,32.272,0,0,0,.095,29.544Zm63.784-.332A32.035,32.035,0,0,0,33.148.021,31.974,31.974,0,0,1,63.881,29.212Z"
      transform="translate(-0.001 -0.005)"
      fill="#fff"
    />
  </svg>
)
