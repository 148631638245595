import React from 'react'
import { colors, alpha, s } from '../../style'

const DecorationPath = ({ section }) => (
  <div className="decorationPath" css={[sDecoration, setStyle(section)]} />
)

const setStyle = (section) => {
  // if (section === 'services') return {}
  // if (section === 'about') return {}
  if (section === 'coop')
    return {
      transform: 'rotate(180deg)',
      [s.sm_down]: { top: '-6rem' },
      [s.md]: { top: '-12rem' },
      [s.xl]: { top: '-6rem' },
      [s.uhd]: { top: '-9rem' },
    }

  if (section === 'career')
    return {
      zIndex: 0,
      transform: 'rotate(180deg)',
      [s.sm_down]: { top: '-6rem' },
      [s.md]: { top: '1rem' },
      [s.xl]: { top: '-6rem' },
      // background: `linear-gradient(178deg, ${colors.yellowOrange.concat(
      //   alpha[0]
      // )} 10%, ${colors.yellowOrange.concat(alpha[100])})`,
    }
}

const sDecoration = {
  pointerEvents: 'none',
  position: 'absolute',
  background: `linear-gradient(178deg, ${colors.white.concat(
    alpha[0]
  )} 10%, ${colors.white.concat(alpha[12])})`,

  [s.sm_down]: {
    bottom: '-6rem',
    width: '250vw',
    left: '-50%',
    height: 500,
    borderBottomRightRadius: '90%',
  },

  [s.md]: {
    bottom: 0,
    width: '150vw',
    left: '-50%',
    height: 500,
    borderBottomRightRadius: '100%',
  },
  [s.xl]: {
    width: '150vw',
    left: '-100%',
  },
  // animation: 'fadeIn 0.4s 1.2s both alternate',
}

export default DecorationPath
