import React from 'react'
import { H1, H2, P1 } from '../components/typo'
import TextWithGlow from '../components/textWithGlow'
import ContactForm from '../components/contactForm'
import ValuationAgreement from '../components/valuationAgreement'
import DecorationPath from '../assets/paths/decorationPath'
import { s, colors, alpha } from '../style'

const { careerSection } = require('../config')

const CareerSection = ({
  theme,
  language,
  h2,
  formData,
  setFormData,
  agreementState,
  setAgreementState,
}) => {
  const { name, title, description, hash } = careerSection
  return (
    <section css={style} id={hash}>
      <DecorationPath section="career" />
      <H1 h2={h2} extraCss={sH1}>
        {name[language]}
      </H1>
      <H2 extraCss={sH2}>
        <TextWithGlow>{title[language]}</TextWithGlow>
      </H2>
      <P1 nocenter>{description[language]}</P1>
      <div css={{ paddingRight: '2rem' }}>
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          language={language}
          theme={theme}
        />
      </div>
      <div
        css={{
          margin: '4rem 0',
          [s.sm_down]: { margin: '2rem 0 3rem' },
          div: { margin: 0, padding: '0 2rem' },
        }}
      >
        <ValuationAgreement
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          language={language}
          theme={theme}
        />
      </div>
    </section>
  )
}

const style = {
  margin: '8rem 0',
  position: 'relative',
}

const sH1 = {
  [s.md]: { paddingBottom: '0rem', marginBottom: 0 },
}

const sH2 = {
  [s.md]: {
    textAlign: 'left',
    paddingLeft: '2rem',
    div: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '0',
    },
  },
}

export default CareerSection
