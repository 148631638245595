import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <path
      id="Path_43"
      d="M53,241.8a32,32,0,1,0,32,32A32.125,32.125,0,0,0,53,241.8ZM35.182,291.618a24.776,24.776,0,0,1-7.313-17.374l24.889,24.687A25.959,25.959,0,0,1,35.182,291.618Zm23.313,6.626L28.556,268.305a25.119,25.119,0,0,1,44.768-9.374l-3.434,2.99A21.1,21.1,0,0,0,53,253.234a20.784,20.784,0,0,0-19.434,13.7l26.263,26.263a20.78,20.78,0,0,0,13.253-14.869H62.131V273.8h16A25.188,25.188,0,0,1,58.495,298.244Z"
      transform="translate(-21 -241.8)"
      fill="#639"
    />
  </svg>
)
