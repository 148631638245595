import React from 'react'
import { navigate } from '@reach/router'
import { H1, P1 } from '../components/typo'
import ContactForm from '../components/contactForm'
import ValuationPicker from '../components/valuationPicker'
import ValuationSlider from '../components/valuationSlider'
import ValuationAgreement from '../components/valuationAgreement'
import ArrowRight from '@material-ui/icons/ArrowForwardIos'

import { s, colors, alpha } from '../style'

const { valuationSection } = require('../config')

const ValuationSection = ({
  theme,
  language,
  selectedCategories,
  setSelectedCategories,
  selectedElements,
  setSelectedElements,
  formData,
  setFormData,
  budgetState,
  setBudgetState,
  agreementState,
  setAgreementState,
  formFilled,
}) => {
  const {
    name,
    description,
    formSections,
    helpSection: { headline, text, link },
  } = valuationSection

  return (
    <section>
      <H1 extraCss={sH1}>{name[language]}</H1>
      {/* <p>
        {selectedCategories.map((item) => (
          <span>{item},</span>
        ))}
      </p> */}
      {description[language] && <P1 nocenter>{description[language]}</P1>}
      <div css={sHelpContainer}>
        <p css={sHelpContainer.text}>{headline[language]}</p>
        <div css={sHelpContainer.link} onClick={() => navigate(`${link}`)}>
          {text[language]}
          <ArrowRight />
        </div>
      </div>
      <ValuationPicker
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        selectedElements={selectedElements}
        setSelectedElements={setSelectedElements}
        formSections={formSections}
        language={language}
        theme={theme}
      />
      <ValuationPicker
        formSections={formSections}
        language={language}
        theme={theme}
        specificID={2}
        filledWithData={formFilled}
      >
        <ContactForm
          formData={formData}
          setFormData={setFormData}
          language={language}
          theme={theme}
        />
      </ValuationPicker>
      <ValuationPicker
        formSections={formSections}
        language={language}
        theme={theme}
        specificID={3}
        filledWithData={budgetState > 0}
      >
        <ValuationSlider
          language={language}
          theme={theme}
          budgetState={budgetState}
          setBudgetState={setBudgetState}
        />
      </ValuationPicker>
      <ValuationPicker
        formSections={formSections}
        language={language}
        theme={theme}
        specificID={4}
        filledWithData={agreementState}
      >
        <ValuationAgreement
          agreementState={agreementState}
          setAgreementState={setAgreementState}
          language={language}
          theme={theme}
        />
      </ValuationPicker>
    </section>
  )
}

const sH1 = {
  marginBottom: '4rem',
}

const sHelpContainer = {
  margin: '0rem 2rem 4rem',
  text: {
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
    opacity: 0.6,
  },
  link: {
    transition: 'color 0.25s ease-in-out',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '1rem',
    textTransform: 'uppercase',
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: 1,
    '&:hover': {
      color: colors.yellowOrange,
    },
    svg: {
      marginLeft: '0.25rem',
      width: 14,
      height: 14,
    },
  },
}

export default ValuationSection
