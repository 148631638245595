import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <path
      id="Icon_material-photo-library"
      d="M51,36.6V7.8A4.814,4.814,0,0,0,46.2,3H17.4a4.814,4.814,0,0,0-4.8,4.8V36.6a4.814,4.814,0,0,0,4.8,4.8H46.2A4.814,4.814,0,0,0,51,36.6ZM24.6,27l4.872,6.5L36.6,24.6l9.6,12H17.4ZM3,12.6V46.2A4.814,4.814,0,0,0,7.8,51H41.4V46.2H7.8V12.6Z"
      transform="translate(-3 -3)"
      fill="#fff"
    />
  </svg>
)
