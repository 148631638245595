import React from 'react'
import { global } from '../style'

const textWithGlow = ({ children }) => {
  return (
    <div css={style}>
      {children &&
        children.map((str, id) =>
          typeof str === 'object' ? (
            <span key={id} css={str.special && global.glowText}>
              &nbsp;{str.text}&nbsp;
            </span>
          ) : (
            <span key={id}>{str}</span>
          )
        )}
    </div>
  )
}

const style = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
}

export default textWithGlow
