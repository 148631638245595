import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="48"
    viewBox="0 0 34 48"
  >
    <path
      id="Icon_material-lightbulb-outline"
      d="M17.214,48.6A2.422,2.422,0,0,0,19.643,51h9.714a2.422,2.422,0,0,0,2.429-2.4V46.2H17.214ZM24.5,3a16.919,16.919,0,0,0-17,16.8,16.736,16.736,0,0,0,7.286,13.776V39a2.422,2.422,0,0,0,2.429,2.4H31.786A2.422,2.422,0,0,0,34.214,39V33.576A16.736,16.736,0,0,0,41.5,19.8,16.919,16.919,0,0,0,24.5,3Zm6.921,26.64-2.064,1.44V36.6H19.643V31.08l-2.064-1.44a11.952,11.952,0,0,1-5.221-9.84,12.144,12.144,0,0,1,24.286,0A11.952,11.952,0,0,1,31.421,29.64Z"
      transform="translate(-7.5 -3)"
      fill="#fff"
    />
  </svg>
)
