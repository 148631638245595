import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="48"
    viewBox="0 0 38 48"
  >
    <path
      id="Icon_material-description"
      data-name="Icon material-description"
      d="M29.75,3h-19A4.769,4.769,0,0,0,6.024,7.8L6,46.2A4.769,4.769,0,0,0,10.726,51H39.25A4.789,4.789,0,0,0,44,46.2V17.4ZM34.5,41.4h-19V36.6h19Zm0-9.6h-19V27h19Zm-7.125-12V6.6L40.438,19.8Z"
      transform="translate(-6 -3)"
      fill="#fff"
    />
  </svg>
)
