import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="25.414"
    viewBox="0 0 64 25.414"
  >
    <g id="PWA_logo" transform="translate(-0.002 0.003)">
      <g id="Group_29" dataname="Group 29" transform="translate(47.107 1.315)">
        <path
          id="Path_75"
          dataname="Path 75"
          d="M1117.707,528.218l1.849-4.676h5.338l-2.534-7.093,3.168-8.013,9.074,24.1h-6.692l-1.551-4.316Z"
          transform="translate(-1117.707 -508.437)"
          fill="#3d3d3d"
        />
      </g>
      <g id="Group_30" dataname="Group 30" transform="translate(16.458 -0.003)">
        <path
          id="Path_76"
          dataname="Path 76"
          d="M674.7,512.415l9.714-24.1h-6.44l-6.645,15.572-4.725-15.572h-4.95l-5.073,15.572-3.578-7.1-3.238,9.978,3.288,5.643h6.337l4.585-13.964,4.371,13.964Z"
          transform="translate(-649.767 -488.318)"
          fill="#5a0fc8"
        />
      </g>
      <g id="Group_31" dataname="Group 31" transform="translate(0.002 -0.002)">
        <path
          id="Path_77"
          dataname="Path 77"
          d="M404.7,504.158h3.967a11.688,11.688,0,0,0,3.21-.4L412.9,500.6l2.867-8.835a7.025,7.025,0,0,0-.748-.982q-2.208-2.445-6.461-2.444h-9.973v24.1H404.7Zm5.25-10.281a3.152,3.152,0,0,1,.862,2.324,3.393,3.393,0,0,1-.758,2.327,3.944,3.944,0,0,1-3.062.956H404.7v-6.474h2.309A4.057,4.057,0,0,1,409.951,493.877Z"
          transform="translate(-398.588 -488.334)"
          fill="#3d3d3d"
        />
      </g>
    </g>
  </svg>
)
