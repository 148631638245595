import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <path
      id="Icon_material-library-books"
      data-name="Icon material-library-books"
      d="M7.8,12.6H3V46.2A4.814,4.814,0,0,0,7.8,51H41.4V46.2H7.8ZM46.2,3H17.4a4.814,4.814,0,0,0-4.8,4.8V36.6a4.814,4.814,0,0,0,4.8,4.8H46.2A4.814,4.814,0,0,0,51,36.6V7.8A4.814,4.814,0,0,0,46.2,3ZM43.8,24.6h-24V19.8h24Zm-9.6,9.6H19.8V29.4H34.2ZM43.8,15h-24V10.2h24Z"
      transform="translate(-3 -3)"
      fill="#fff"
    />
  </svg>
)
