import React, { useState, useContext } from 'react'
import { navigate } from '@reach/router'
import { s, alpha, colors } from '../style'

import { Collapse, Fade, ButtonBase } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DispatchContext } from '../context'

import { Icons } from './serviceItem'

const { slugs, dictionary } = require('../config')

const ServiceItemNew = ({
  language,
  title,
  description,
  icon,
  selectOption,
  // setFocused,
  // focused,
  // section,
  // id,
  iconColors,
}) => {
  const [focusedLocal, setFocusedLocal] = useState(false)
  const dispatch = useContext(DispatchContext)

  return (
    <div
      css={[sContainer, focusedLocal && sContainer.active]}
      onClick={() => setFocusedLocal(!focusedLocal)}
      // onClick={() => (focused === id ? setFocused(null) : setFocused(id))}
    >
      <ButtonBase css={sContainerTopPart}>
        <div css={{ display: 'flex' }}>
          <div
            css={[
              sIcon,
              focusedLocal && sIcon.active,
              !iconColors && { svg: { 'path, rect': { fill: 'white' } } },
            ]}
            className="sIcon"
          >
            {Icons[icon]}
          </div>
          <h4 css={[sText, focusedLocal && sText.active]} className="sText">
            {title}
          </h4>
        </div>
        <ExpandMoreIcon
          css={[
            { transition: 'transform 0.25s ease-in, opacity 0.25s ease-in' },
            focusedLocal
              ? {
                  transform: 'rotate(180deg)',
                  opacity: 1,
                }
              : { transform: 'rotate(0deg)', opacity: 0.3 },
          ]}
        />
      </ButtonBase>
      <Collapse in={focusedLocal}>
        <div>
          <p css={[sDecription]}>{description}</p>
          {/* {selectOption && (
            <p
              onClick={() => {
                console.log(selectOption)
                dispatch({
                  type: 'SET_SELECTED_SERVICE',
                  payload: selectOption,
                })
                navigate(`${slugs.valuation}`)
              }}
            >
              {dictionary.selectOption[language]}
            </p>
          )} */}
        </div>
      </Collapse>
    </div>
  )
}

export const sContainer = {
  transition:
    'background-color 0.25s ease-in, filter 0.25s ease-in, opacity 0.25s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: colors.white.concat(alpha[12]),
  borderRadius: '1rem',
  [s.sm_down]: {
    marginBottom: '2rem',
    width: 'calc(100% - 4rem)',
  },
  [s.md]: {
    margin: '0rem 2rem 2rem 0rem',
  },
  opacity: 0.6,

  active: {
    opacity: 1,
    backgroundColor: colors.white.concat(alpha[16]),
    '&:hover': {
      [s.hover]: {
        opacity: 1,
        // backgroundColor: colors.white.concat(alpha[16]),
      },
    },
  },

  '&:hover': {
    [s.hover]: {
      opacity: 0.8,
      backgroundColor: colors.white.concat(alpha[16]),
    },
  },
}

const sContainerTopPart = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingRight: '1rem',
  borderRadius: '1rem',
  fontFamily: 'Quicksand',
}

export const sIcon = {
  margin: '0 0.5rem',
  opacity: 1,
  flexShrink: 0,
  // transition: 'background-color 0.2s ease-in, background 0.3s ease-in-out, opacity 0.25s ease-in-out',
  width: 60,
  height: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // borderRadius: 48,
  // backgroundColor: colors.white.concat(alpha[0]),
  svg: {
    maxHeight: 30, //40
    maxWidth: 30, //40
    transition: 'opacity 0.25s ease-in',
    // filter: 'brightness(1000%) contrast(50%) grayscale(1)',
    // opacity: 0.6,
    width: 30, //40
    height: 'auto',
  },

  active: {
    opacity: 1,
    svg: { opacity: 1 },
  },
}

const sText = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease-in, font-weight 0.3s ease-out',
  [s.sm_down]: { fontSize: '1rem', fontWeight: 700 },
  [s.md]: { fontSize: '1.125rem', fontWeight: 300 },
  textAlign: 'center',
  color: colors.white.concat(alpha[100]),

  active: {
    // color: colors.black.concat(alpha[100]),
    color: colors.white.concat(alpha[100]),
  },
}

const sDecription = {
  fontSize: '0.875rem',
  padding: '1rem',
  color: colors.white.concat(alpha[60]),
  cursor: 'pointer',
}

export default ServiceItemNew
